import React from 'react';
import {Flex} from "antd";
import {useTranslation} from "react-i18next";
import Translate from "../../../components/Translate";

const FiltersList = ({priceFrom, priceTo, producers, setPriceRange, setSelectedProducers, maxPrice, currentCatalog, onClearCatalogFilter}) => {

    const {t} = useTranslation()
    const clearPriceRangeFilter = () =>  {
        setPriceRange([0, maxPrice])
        // fetchGoods()
    }

    const clearProducersFilter = (e) =>  {
        producers.delete(Number(e.target.id))
        setSelectedProducers(new Map(producers))
        // fetchGoods()
    }



    return (
        <Flex gap={10} wrap={"wrap"} className={"nonSelect"}>
            {priceFrom !== 0 || priceTo !== maxPrice
                ?
                <Flex className="filter" align={"center"} >
                    <span className="filterVal">{priceFrom}-{priceTo}$</span>
                    <span className="closeFilter" onClick={clearPriceRangeFilter}>&#10006;</span>
                </Flex>
                :
                <></>
            }

            {currentCatalog !== null
                ?
                <div className="filter" >
                    <span className="filterVal"><Translate t={t} text={currentCatalog}/></span>
                    <span className="closeFilter" onClick={onClearCatalogFilter}>&#10006;</span>
                </div>
                :
                <></>
            }

            {priceFrom != null || priceTo != null
                ?
                Array.from(producers, ([id, name]) => ({ id, name })).map((producer) =>
                    <div key={producer.id} className="filter">
                        <span className="filterVal">{producer.name}</span>
                        <span className="closeFilter" id={producer.id} onClick={clearProducersFilter}>&#10006;</span>
                    </div>
                )
                :
                <></>
            }
        </Flex>
    );
};

export default FiltersList;