import React, {useContext} from 'react';
import './Good.css'
import {Button, Flex, Popconfirm} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import Translate from "../Translate";
import {getImageUrl} from "../../API/services/ImagesService";
import {checkPermission} from "../../API/services/AuthService";
import {useTranslation} from "react-i18next";
import {Carousel} from "react-bootstrap";
import GoodVideo from "./GoodVideo";
import {useDispatch} from "react-redux";
import {setDeleteGoodsId, setUpdateGoodsId} from "../../features/goods/goodsSlice";
import {AuthContext} from "../../AuthContext";


const Good = ({good}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {jwt} = useContext(AuthContext)

    const onDelete = () => {
        dispatch(setDeleteGoodsId(good.id))
    }

    const onUpdate = () => {
        dispatch(setUpdateGoodsId(good.id))
    }

    return (
        <div className="good" id={"g-" + good.id} key={"good-" + good.id}>

            <Carousel touch={true} controls={false}>
                {(good.videoUrl !== 'null' && good.videoUrl !== null) &&
                    <Carousel.Item key={"good-video-" + good.id + "-" + good.videoUrl}>
                        <a href={good.url} target={"_blank"} style={{color: "black"}}  rel="noreferrer">
                            <video autoPlay
                                   muted
                                   className="goodImage"
                                   src={good.videoUrl}
                            ></video>
                        </a>
                    </Carousel.Item>
                }

                {good.images.map((img ) =>
                    <Carousel.Item key={"good-image-" + good.id + "-" + img + "-" + good.name.substring(0, 4)}>
                        <a href={good.url} target={"_blank"} style={{color: "black"}}  rel="noreferrer">

                            <img
                                className="goodImage" src={getImageUrl(img)}
                                alt="good"
                            />
                        </a>
                    </Carousel.Item>
                )}

                {good.externalImages.map((img ) =>
                    <Carousel.Item key={"good-externalImage-" + good.id + "-" + img + "-" + good.name.substring(0, 20)}>
                        <a href={good.url} target={"_blank"} style={{color: "black"}}  rel="noreferrer">

                            <img
                                className="goodImage" src={img}
                                alt="good"
                            />
                        </a>
                    </Carousel.Item>
                )}
            </Carousel>
            <Flex className={"goodInfo"} justify={"space-between"} vertical={true}>
                <a href={good.url} target={"_blank"} style={{color: "black"}}  rel="noreferrer">
                    <span style={{color: "black"}}>
                        <Translate t={t} text={good.name} />
                    </span>
                </a>

                <span style={{fontWeight: 700, marginTop: 10}}>{'$' + good.price} {good.oldPrice !== 0 && <> - <s>{good.oldPrice}</s>$</> }</span>

                <a style={{color: "black"}}  href={good.url} target={"_blank"} rel="noreferrer">
                <span
                    style={{color: "black", marginTop: 20}}
                    className="link">
                    { t('SeeDetails') }
                </span>
                </a>

                {checkPermission(jwt, "manage:goods") &&
                    <Flex align={"center"} gap={5}>
                        <Popconfirm
                            title="Видалення товару"
                            description="Ви впевнення що хочене видалити товар? Дія незворотня."
                            onConfirm={onDelete}
                            okText="Гаразд"
                            cancelText="Відміна"
                        >
                            <Button danger  style={{marginTop: 5}} icon={<DeleteOutlined />}/>
                        </Popconfirm>
                        <Button  onClick={onUpdate} icon={<EditOutlined/>}/>

                        {/*<ManageGoodForm edit good={good} icon={<EditOutlined/>} />*/}
                    </Flex>
                }
            </Flex>
        </div>
    );
};

export default Good;