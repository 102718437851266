import {callAndGetResult} from "./ExternalApiService";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getAllCatalogs = async () => {
    const config = {
        url: `${apiServerUrl}/api/good/manufacture-machine/catalog/all`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };
    return callAndGetResult(config)
};

export const newCatalog = async (data, jwt) => {
    const config = {
        url: `${apiServerUrl}/api/protected/catalog/new`,
        method: "POST",
        data : data,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    };
    return callAndGetResult(config)
};

export const updateCatalog = async (data, id, jwt) => {
    const config = {
        url: `${apiServerUrl}/api/protected/catalog/${id}/update`,
        method: "PUT",
        data : data,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    };
    return callAndGetResult(config)
};

export const deleteCatalogById = async (id, jwt) => {
    const config = {
        url: `${apiServerUrl}/api/protected/catalog/${id}/delete`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    };
    return callAndGetResult(config)
};