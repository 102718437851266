import {PriceSort} from "../../enums/PriceSort";



export default class GoodUtil {
    static sortGood(sort, goods) {
        if (sort === PriceSort.HigherFirst) {
            let res = goods.sort((good1, good2) => this.compare(good2.price, good1.price))
            return res
        } else {
            return goods.sort((good1, good2) => this.compare(good1.price, good2.price))
        }
    }

    static compare(n1, n2) {
        if (n1 > n2) {
            return 1
        }
        if (n1 < n2) return -1
        else return 0
    }
}