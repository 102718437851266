import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {Empty, Flex, Image} from "antd";
import {findByNameOrSerialNumber} from "../../../API/services/SearchService";
import Search from "antd/es/input/Search";
import Highlighter from "react-highlight-words";
import {getImageUrl} from "../../../API/services/ImagesService";
import useInput from "../../../hooks/useInput";

const MobileSearchGoods = ({handleCancel, options, setOptions, value, onChange}) => {

    const nav = useNavigate()
    const location =useLocation()

    const val = useInput(value)
    const handleSearch = async (query) => {
        if (query.length <= 255 && query.length > 0) {
            const {data, error} = await findByNameOrSerialNumber(query)
            if (data) {
                setOptions(data)
            }
            if (error) {
                setOptions([])
                throw new Error("Search error!")
            }
        }
    };

    const onSelect = (value) => {
        handleCancel()
        nav("/manufacture-machine/" + value)
    };


    return (
        <div style={{marginTop: 10}}>
            <Flex vertical={true}>
                <Search
                    onChange={val.onChange}
                    value={val.value}
                    placeholder="input search text"
                    allowClear
                    onSearch={handleSearch}
                    style={{ width: 304 }}
                />
                <span style={{marginLeft: 3, paddingTop: 3}}>{options.length} items found.</span>
            </Flex>

            <div style={{margin: "20px 0"}}>
                {options.length > 0
                    ?

                    <Flex vertical={true} gap={10}>
                        {options.map((good) =>
                                <Flex key={good.id} gap={10} align={"center"} >
                                    <Image style={{minWidth: 100}} width={100} src={getImageUrl(good.imageId)}/>
                                    <Flex onClick={() => onSelect(good.id)} style={{cursor: "pointer"}} vertical={true}>
                                        <Highlighter
                                            style={{overflow: "hidden", textOverflow: "ellipsis"}}
                                            searchWords={[val.value]}
                                            autoEscape={true}
                                            textToHighlight={good.name}
                                        />

                                        <span style={{fontWeight: "bold"}}>{good.serialNumber}</span>
                                    </Flex>
                                </Flex>
                            )}
                    </Flex>
                    :
                    <Empty style={{margin: "30px 0"}} description={"There are no items found."} />
                }
            </div>
        </div>
    );
};

export default MobileSearchGoods;