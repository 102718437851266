import React, {useState} from 'react';
import './Dropdown.css'
import './Header.css'
import ManagePanel from "../../pages/ManagePanel/ManagePanel";
import {Flex, Image, Modal} from "antd";
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import HeaderNavbar from "./HeaderNavbar/HeaderNavbar";
import SearchGoods from "./SearchGoods/SearchGoods";
import {SearchOutlined} from "@ant-design/icons";
import '../../components/Header/SearchGoods/SearchIcon.css'
import MobileSearchGoods from "./SearchGoods/MobileSearchGoods";
import useInput from "../../hooks/useInput";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {getImageUrl} from "../../API/services/ImagesService";
import {useDispatch, useSelector} from "react-redux";

const Header = ({siteInfo}) => {

    const nav = useNavigate()
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { user, isAuthenticated} = useAuth0()


    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [options, setOptions] = useState([]);
    const {value, onChange} = useInput('')
    const setOpt = (opt) => {
        setOptions(opt)
    }


    return (
        <div className="header" >
                <div className="headerItem logoAndLangWrapper customContainer">
                    <div className={"logoAndLangWrapper2"}>
                        <Flex style={{width: "fit-content"}}>
                            <div id="logoWrapper">
                                <div id="logo" style={{cursor: "pointer"}} onClick={() => nav("/all")}>
                                    <img src={getImageUrl(siteInfo.logoId)} alt="logo"/>
                                    <span style={{fontWeight: 400}}>{siteInfo.title}</span>
                                </div>
                            </div>

                            <SearchGoods onChange={onChange}  showModal={showModal} options={options} setOptions={setOpt}  style={{position: "relative"}}/>
                        </Flex>
                        <Flex align={"center"} gap={10} >
                                <SearchOutlined onClick={showModal} className={"searchIcon"} />

                            <Modal title="Seach" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                                <MobileSearchGoods handleCancel={handleCancel} value={value} onChange={onChange} options={options} setOptions={setOpt}/>
                            </Modal>

                            <div style={{position: "relative"}}>
                                {isAuthenticated
                                    ?
                                    <Flex vertical={true} align={"center"} justify={"space-between"} style={{top: -12, position:"relative", height: 32}}>
                                        <Image preview={false} style={{borderRadius: 55, border: "solid #3c3c3c 2px", width: 40}} src={user.picture} alt={user.name} />
                                        <span style={{fontSize: 10}}>{user.name}</span>
                                    </Flex>
                                    :
                                    <></>
                                }
                            </div>

                            <LanguageSelect/>
                            <ManagePanel siteInfo={siteInfo}/>
                        </Flex>
                    </div>
                </div>

            <HeaderNavbar/>
        </div>
    );
};

export default Header;