import React from 'react';
import './GoodLoader.css'

const GoodLoader = () => {
    return (
        <div className={"goods"}>
            {[...Array(10)].map((e, index) =>
                <div className={"loader_goodWrapper"} key={"loadedGood-" + index}>
                    <div className={"loader_producerLogo loaded"}></div>
                    <div className={"loader_goodImg loaded"}></div>
                    <div className={"loader_text"}>
                        <span className={"loaded"} style={{borderRadius: 5}}>HX-ZDXZ-190/7L</span>
                    </div>

                    <div className={"loader_text"}>
                        <span className={"loaded"} style={{borderRadius: 5}}>Automatic facial tissues making machine</span>
                    </div>

                    <div className={"loader_text"} style={{marginTop: 15}}>
                        <span className={"loaded"} style={{borderRadius: 5}}>$55000</span>
                    </div>

                    <div className={"loader_text"} style={{marginTop: 35}}>
                        <span className={"loaded"} style={{borderRadius: 5}}>See details</span>
                    </div>

                </div>
            )}
        </div>

    );
};

export default GoodLoader;