import React, {useContext, useEffect, useState} from 'react';
import instagram from '../../assets/img/footer/instagram.svg'
import youtube from '../../assets/img/footer/youtube.svg'
import './Footer.css'
import '../../components/UI/MainStylesKit.css'
import {Flex, FloatButton, Image, notification, Skeleton, Tooltip} from "antd";
import {getImageUrl} from "../../API/services/ImagesService";
import {useTranslation} from "react-i18next";
import {CheckOutlined, CloseCircleTwoTone} from "@ant-design/icons";
import EditableText from "./EditableText";
import {AuthContext} from "../../AuthContext";
import {updateSiteInfo} from "../../API/services/SiteInfoService";
import Translate from "../Translate";

const Footer = ({siteInfo}) => {
    const {t} = useTranslation()
    const [buttonIsActive, setButtonIsActive] = useState(false)
    const [aboutUs, setAboutUs] = useState(undefined);
    const [primaryPhone, setPrimaryPhone] = useState();
    const [secondaryPhone, setSecondaryPhone] = useState();
    const [email, setEmail] = useState();
    const [api, contextHolder] = notification.useNotification();
    const {jwt} = useContext(AuthContext)

    useEffect(() => {
        if (siteInfo !== undefined) {
            setAboutUs(siteInfo.footerAboutUsText)
            if (siteInfo.contacts) {
                setPrimaryPhone(siteInfo.contacts.primaryPhone)
                setSecondaryPhone(siteInfo.contacts.secondaryPhone)
                setEmail(siteInfo.contacts.email)
            }
        }
    }, [siteInfo]);

    useEffect(() => {
        if (jwt) {
            setButtonIsActive(
                aboutUs !== siteInfo.footerAboutUsText ||
                primaryPhone !== siteInfo.contacts.primaryPhone ||
                secondaryPhone !== siteInfo.contacts.secondaryPhone ||
                email !== siteInfo.contacts.email
            )
        }
    }, [aboutUs, email, jwt, primaryPhone, secondaryPhone]);

    const onCancelChanges = () => {
        setButtonIsActive(false)
        setAboutUs(siteInfo.footerAboutUsText)
        setPrimaryPhone(siteInfo.contacts.primaryPhone)
        setSecondaryPhone(siteInfo.contacts.secondaryPhone)
        setEmail(siteInfo.contacts.email)
    }

    const onOk = async () => {
        setButtonIsActive(false)
        siteInfo.footerAboutUsText = aboutUs
        siteInfo.contacts.primaryPhone = primaryPhone
        siteInfo.contacts.secondaryPhone = secondaryPhone
        siteInfo.contacts.email = email

        const {error} = await updateSiteInfo(jwt, siteInfo);

        if (error) {
            api.error({message: "Can't update contacts"})
        } else {
            api.success({message: "Updated"})
        }
    }
    return (
        <div className="footerWrapper">
            {contextHolder}

            {buttonIsActive &&
                <FloatButton.Group shape="square" style={{ left: 24 }}>
                    <Tooltip title={<Translate text={"You have unsaved changes, click on the button to save changes."} t={t}/>} trigger="hover" defaultOpen>
                        <FloatButton onClick={onOk} type={"primary"} icon={<CheckOutlined />} />
                        <FloatButton onClick={onCancelChanges} icon={<CloseCircleTwoTone twoToneColor={"red"} />}/>
                    </Tooltip>
                </FloatButton.Group>
            }

            <Flex vertical={false} className="footer customContainer" >
                <Flex vertical={true} className={"compLogo nonSelect"} align={"center"} justify={"center"}>
                    <div id="footerLogoWrapper">
                        <img
                            style={{borderRadius: 3}}
                             src={ getImageUrl(siteInfo.logoId) }
                            width="28px"
                            height="30"
                            alt="logo"
                        />
                        {siteInfo.title
                            ?
                            <p style={{padding: 0, margin: 0}}>
                                {siteInfo.title}
                                <span>.COM</span>
                            </p>
                            :
                            <Skeleton/>
                        }
                    </div>
                </Flex>

                <Flex vertical={true} className={"nonSelect"} style={{marginTop: 20, maxWidth: 800, width: "100%"}}>
                    <div>
                        <span className={"bold"}  style={{fontSize: 18}} >{ t('aboutUs') }</span>
                    </div>
                    {aboutUs !== undefined
                        ?
                        <EditableText color={"#8f8f8f"}
                                      onChange={setAboutUs}
                                      value={aboutUs}
                        />
                        :
                        <Skeleton/>
                    }
                </Flex>

                <Flex vertical={true} style={{display: "flex", marginTop: 20}}>
                    <span className={"nonSelect bold"} style={{fontSize: 18}} >{ t('contacts') }</span>
                    <div id="contacts">
                        {email  !== undefined
                            ?
                            <EditableText color={"white"}
                                          onChange={setEmail}
                                          value={email}
                            />
                            :
                            <Skeleton/>
                        }

                        <Image preview={false} style={{cursor: "pointer", width: 30}} src={instagram} alt="instagram"/>

                        <Image preview={false} style={{cursor: "pointer", width: 45}} src={youtube} alt="youtube"/>
                    </div>
                </Flex>

            </Flex>
        </div>
    );
};

export default Footer;