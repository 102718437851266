import {TranslateClient, TranslateTextCommand} from "@aws-sdk/client-translate";


export default class TranslateService {
    static client = new TranslateClient({
        region: "ap-southeast-1",
        credentials: {
            accessKeyId: "none",
            secretAccessKey: "none"
        }
    });

    static translationMap = new Map()

    static translateText(targetLanguage, text) {
        const params = {
            Text: text,
            SourceLanguageCode: "en",
            TargetLanguageCode: targetLanguage
        }

        if (targetLanguage === "en") {
            return Promise.resolve({
                fromCache: true,
                TranslatedText: text
            })
        }

        let key = text + targetLanguage
        if (this.translationMap.has(key)) {
            return Promise.resolve({
                fromCache: true,
                TranslatedText: this.translationMap.get(key)
            })
        } else {
            const command = new TranslateTextCommand(params);
            return this.client.send(command)
        }

        const command = new TranslateTextCommand(params);
        return this.client.send(command)
    }
}
