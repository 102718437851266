import React, {useContext, useState} from 'react';
import {Form, Modal, notification, Upload} from "antd";
import ImageUtil from "../../API/util/ImageUtil";
import {deleteGoodImageByIdAngGoodId} from "../../API/services/GoodService";
import {PlusOutlined} from "@ant-design/icons";
import {deleteImageById, saveImageAndGetId} from "../../API/services/ImagesService";
import {AuthContext} from "../../AuthContext";

const ImageUpload = ({fileList, handleChange, label, required, edit, goodId}) => {
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const {jwt} = useContext(AuthContext)

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await ImageUtil.getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );



    const onRemove = async (e) => {
        if (jwt) {

            if (edit) {
                const {error} = await deleteGoodImageByIdAngGoodId(jwt, e.key, goodId)
                if (error) notification.error({message: "Can't delete an image!"})
            } else {
                const {error} = await deleteImageById(jwt, e.response)
                if (error) notification.error({message: "Can't delete an image!"})
            }
        } else notification.warning({message: "not auth"})
    }

    const uploadFile = async ({file, onSuccess }) => {
        const {data} = await saveImageAndGetId(jwt, file)
        onSuccess(data.id)
    }

    return (
        <div>
            <Form.Item
                label={<label>{label}</label>}
                name="upload"
                rules={[{ required: required, message: "Please upload a " + label}]}
            >
                <Upload
                    onRemove={onRemove}
                    customRequest={uploadFile}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {fileList.length >= 10 ? null : uploadButton}
                </Upload>
            </Form.Item>

            <Modal width={"80%"} open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="revision"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </div>

    );
};

export default ImageUpload;