import React, {useContext, useEffect, useState} from 'react';
import {Button, Flex, Image, Input, InputNumber, Modal, notification} from "antd";
import useInput from "../../hooks/useInput";
import {addNewManufactureMachine, updateGood} from "../../API/services/GoodService";
import useSelectInput from "../../hooks/useSelectInput";
import SelectCatalog from "./SelectCatalog";
import useUpload from "../../hooks/useUpload";
import {DeleteOutlined} from "@ant-design/icons";
import './ManageGoodForm.css'
import {useTranslation} from "react-i18next";
import Translate from "../Translate";
import {useDispatch, useSelector} from "react-redux";
import {setGoodsList, setUpdateGoodsId} from "../../features/goods/goodsSlice";
import {AuthContext} from "../../AuthContext";

const ManageGoodForm = ({icon, btnText, edit, setIsModalOpen, isModalOpen}) => {
    const goodsList = useSelector((state) => state.goods.goodsList)
    const dispatch = useDispatch()
    const currentCatalog = useSelector((state) => state.catalogs.currentCatalog)
    const catalogsList = useSelector((state) => state.catalogs.catalogsList)
    const updateGoodsId = useSelector((state) => state.goods.updateGoodsId)
    const [newImageUrl, setNewImageUrl] = useState(null)
    const [compressedData, setCompressedData] = useState(null)

    const [externalImagesUrls, setExternalImagesUrls] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [good, setGood] = useState(null)
    const price = useInput(null)
    const oldPrice = useInput(null)
    const name = useInput(null)
    const url = useInput(null)
    const {jwt} = useContext(AuthContext)
    const videoUrl = useInput(null)
    const catalog = useSelectInput(null)
    const [fileList, , onImagesChange, onCancelInput, getImagesIds] = useUpload();
    const {t} = useTranslation()



    const clearData = () => {
        setGood(null)
        price.onChange(null)
        oldPrice.onChange(null)
        name.onChange(null)
        url.onChange(null)
        videoUrl.onChange(null)
        setExternalImagesUrls([])
    }

    const onCancel = () => {
        setIsModalOpen(false)
        onCancelInput()
        if (edit) {
            dispatch(setUpdateGoodsId(null))
        }
    }

    useEffect(() => {
        if (currentCatalog !== null) {
            const c = catalogsList.find((e) => e.name === currentCatalog)
            const val = c.id
            catalog.onChange(val)
        } else {
            catalog.onChange(undefined)
        }

    }, [currentCatalog]);

    useEffect(() => {
        if (edit && isModalOpen) {
            const elem = goodsList.find((e) => e.id === updateGoodsId)
            setGood(elem)
            price.onChange(elem.price)
            oldPrice.onChange(elem.oldPrice)
            name.onChange(elem.name)
            url.onChange(elem.url)
            videoUrl.onChange(elem.videoUrl === undefined ? null : elem.videoUrl)
            catalog.onChange(elem.catalog.id)
            setExternalImagesUrls(elem.externalImages === undefined ? [] : elem.externalImages)
        }
    }, [isModalOpen]);


    const showModal = () => {
        setIsModalOpen(true);
    };

    const warning = (text) => {
        notification.warning({message : text})
    }


    const onSubmit = async () => {

        if (!catalog.value) {
            warning('Вкажіть каталог товару')
            return
        }

        if (!name.value) {
            warning('Пусте поле з назвою товару')
            return
        }

        if (name.value.length < 5 || name.value > 255) {
            warning('Довжина назви повинна бути між 5 і 255 символами')
            return
        }

        if (!price.value) {
            warning('Вкажіть ціну товару')
            return
        }

        if (!url.value) {
            warning('Вкажіть посилання на товар')
            return
        }

        if (externalImagesUrls.length === 0 && videoUrl.value) {
            warning('Додайте хоча б одне зоображення чи відео')
            return
        }

        let formData = new FormData();
        formData.append("images", getImagesIds())
        formData.append("externalImagesUrls", externalImagesUrls)
        formData.append("videoUrl", videoUrl.value)
        formData.append("price", price.value)
        formData.append("oldPrice", oldPrice.value === undefined ? 0 : oldPrice.value)
        formData.append("name", name.value)
        formData.append("url", url.value)


        if (good !== null) {
            formData.append("id", good.id)
        }

        if (jwt) {
            if (edit) {
                formData.append("catalogId", typeof catalog.value === 'object' ? catalog.value.id : catalog.value)
                setIsLoading(true)
                const {data, error} = await updateGood(jwt, good.id, formData)
                setIsLoading(false)
                if (data) {
                    notification.success({message: <Translate t={t} text={"The goods was updated successfully!"}/>})
                    const elem = goodsList.find((e) => e.id === good.id)
                    const arr = [...goodsList]
                    arr[goodsList.indexOf(elem)] = data
                    dispatch(setGoodsList(arr))
                    setIsModalOpen(false)
                    dispatch(setUpdateGoodsId(null))
                }
                if (error) {
                    notification.error(
                        {message: <Translate t={t} text={"Error when updating the goods"}/>,
                            description: <Translate t={t} text={error.message}/>,
                            duration : 5
                        })
                    console.error(error)
                }

            } else {
                formData.append("catalogId", catalog.value)
                setIsLoading(true)
                const {error,data } = await addNewManufactureMachine(jwt, formData)
                setIsLoading(false)
                if (data) {
                    dispatch(setGoodsList([data, ...goodsList]))
                    notification.success({message: "The goods was saved successfully!"})
                    clearData()
                    setIsModalOpen(false)
                }

                if (error) {
                    notification.error({
                        message : <Translate t={t} text={"Error when saving the goods"}/>,
                        description: <Translate t={t} text={error.message}/>,
                        duration : 5
                    })
                    console.error(error.message)
                }
            }
        } else notification.error({message: "not auth"})
    }

    const addImage = (url) => {
        let arr = []
        if (externalImagesUrls) {
            arr.push(...externalImagesUrls)
        }
        if (url) {
            arr.push(url)
        }
        setExternalImagesUrls(arr)
        setNewImageUrl(null)
    }
    const onRemoveImageUrl = (imageUrl) => {
        setExternalImagesUrls(externalImagesUrls.filter((e) => e !== imageUrl))
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    useEffect(() => {
        if (compressedData !== null) {
            const arr = compressedData.split(";")
            if (arr.length === 5) {
                const goodName = arr[1]
                const old = arr[2].split(' ')[3]
                const nowPrice = arr[3].split(' ')[3]
                const goodUrl = arr[4].split(' ')[1]
                name.onChange(goodName)
                oldPrice.onChange(old)
                url.onChange(goodUrl)
                price.onChange(nowPrice)
                setCompressedData(null)
            } else {
                warning("Неправильні дані!")
            }
        }
    }, [compressedData]);

    const onClear = () => {
        price.onChange()
        oldPrice.onChange()
        name.onChange()
        url.onChange()
        videoUrl.onChange(null)
        catalog.onChange(undefined)
        newImageUrl.onChange()
        setExternalImagesUrls()
    }


    const onImageAdd = (e) => {
        const url = e.target.value
        if (validURL(url)) {
            addImage(url)
        } else {
            notification.warning({
                message: "Неправильне посилання", duration: 5, description:
                    <>
                        <p>Має бути формат - https://адреса-картинки</p>
                        <strong>Ви ввели:</strong>
                        <p>{url}</p>
                    </>
            })
        }
        setNewImageUrl(null)

    }

    return (
        <div>
            <Modal
                width={800}
                title={edit ? "Оновити товар" : "Новий товар"}
                open={isModalOpen}
                onCancel={onCancel}
                okText={"Submit"}
                onOk={onSubmit}
                footer={false}
            >
                <Flex vertical gap={10} className={"ManageGoodForm"}>
                    <Flex gap={5}>
                        <Input onChange={(e) => setCompressedData(e.target.value)} value={compressedData} placeholder={"data"}/>
                        <Button danger onClick={onClear}>Очистити</Button>
                    </Flex>
                    <Flex wrap={"wrap"} justify={"space-between"} style={{padding: 5, backgroundColor: "#F3F5F6"}}>

                        <Flex gap={10} vertical>

                            <Flex vertical>
                                <p>Назва</p>
                                <Input {...name} />
                            </Flex>

                            <Flex vertical>
                                <p>Посилання на товар</p>
                                <Input {...url} rows={4}/>
                            </Flex>

                        </Flex>
                        <Flex  gap={10} vertical>
                            <Flex vertical>
                                <Flex>
                                    <Flex gap={5}>
                                        <p>Ціна</p>
                                        <InputNumber {...price}/>
                                    </Flex>

                                    <Flex gap={5}>
                                        <p>Стара ціна</p>
                                        <InputNumber {...oldPrice}/>
                                    </Flex>
                                </Flex>

                            </Flex>
                            <Flex style={{minWidth: 270}} vertical>
                                <SelectCatalog  catalog={catalog}/>
                            </Flex>
                        </Flex>
                    </Flex>


                    <Flex vertical style={{  padding: 5, backgroundColor: "#F3F5F6", minHeight: 200}} justify={"space-between"}>

                        <Flex gap={5} align={"center"} style={{padding: 10}}>
                            <span>Посилання на фото</span>
                            <Input style={{height: 50, maxWidth: 200}} onChange={onImageAdd} value={newImageUrl}/>
                            <Button onClick={addImage}>Додати</Button>
                        </Flex>

                        <Flex  gap={5} style={{padding: 20, overflowX: "scroll", overflowY: "hidden"}}>
                            {externalImagesUrls?.map((imgUrl) =>
                                <div key={imgUrl} style={{position: "relative"}}>
                                    <Image style={{maxHeight: "200px", width: "auto"}} src={imgUrl}/>
                                    <DeleteOutlined style={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        color: "red",
                                        backgroundColor: "rgba(2,2,2,0.55)",
                                        padding: 10,
                                        borderBottomLeftRadius: 10
                                    }} onClick={() => onRemoveImageUrl(imgUrl)}/>
                                </div>
                            )}
                        </Flex>
                    </Flex>

                    <Flex style={{  backgroundColor: "#F3F5F6", padding: 5}} justify={"space-between"} gap={10} wrap={"wrap"}>
                        <Flex vertical>
                            <p>Посилання на відео</p>
                            <Flex>
                                <Input {...videoUrl} rows={4}/>
                                <Button danger onClick={() => videoUrl.onChange("null")}>Очистити</Button>
                            </Flex>
                        </Flex>

                        <Flex style={{minHeight:150}}>
                            <span></span>
                            {videoUrl.value &&
                                <video autoPlay muted style={{maxWidth: 300, maxHeight:250}} src={videoUrl.value}></video>
                            }
                        </Flex>
                    </Flex>

                </Flex>

                <Flex style={{marginTop: 10}} vertical={false} justify={"flex-end"} gap={10}>
                    <Button onClick={onCancel}>Відміна</Button>
                    <Button loading={isLoading} type="primary" onClick={onSubmit}>
                        {edit ? 'Оновити' : 'Зберегти'}

                    </Button>
                </Flex>


            </Modal>
            {!edit &&
                <Button onClick={showModal} icon={icon}> {btnText}</Button>
            }
        </div>
    );
};

export default ManageGoodForm;