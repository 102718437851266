import React, {useContext, useEffect, useState} from 'react';
import {Button, Divider, Flex, Input, notification, Select, Space} from "antd";
import {newCatalog} from "../../API/services/CatalogService";
import {PlusOutlined} from "@ant-design/icons";
import {AuthContext} from "../../AuthContext";
import Translate from "../Translate";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setCatalogsList} from "../../features/catalogs/catalogsSlice";

const SelectCatalog = ({catalog}) => {
    const dispatch = useDispatch()
    const catalogsList = useSelector((state) => state.catalogs.catalogsList)
    const {jwt} = useContext(AuthContext)
    const {t} = useTranslation()


    const [catalogName, setCatalogName] = useState('')
    const addCatalog = async () => {
        if (catalogName === '' || catalogName.length < 4 || catalogName.length >= 255) {
            notification.warning({message: <Translate t={t} text={""}/>})
            return
        }
        if (jwt) {
            const formData = new FormData()
            formData.append("name", catalogName)

            const {data, error} = await newCatalog(formData, jwt);
            if (data) {
                dispatch(setCatalogsList([...catalogsList, {id : data.id, name : data.name}]))
            }
            if (error) {
                console.error(error)
                notification.error({message: "error when save catalog"})
            }
        } else notification.warning({message: "not auth"})
    }


    return (
        <Flex vertical>
            <p>Каталог</p>
            <Select
                value={catalog.value}
                dropdownRender={(menu) =>
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />

                        <Space style={{ padding: '0 8px 4px' }}>

                            <Input
                                placeholder="Назва каталогу"
                                value={catalogName}
                                onChange={(e) => setCatalogName(e.target.value)}
                            />
                            <Button onClick={addCatalog} type="text" icon={<PlusOutlined />} >
                                Новий каталог
                            </Button>
                        </Space>
                    </>
                }
                    onChange={catalog.onChange}
                style={{ width: "100%" }}
                allowClear
                options={catalogsList.map((catalog) => {
                    return {value: catalog.id, label: catalog.name}
                })}
            />
        </Flex>
    );
};

export default SelectCatalog;