import React, {useEffect, useState} from 'react';
import {getAllMessages} from "../../API/services/ClientMessageService";
import ClientMessage from "./ClientMessage";
import {Empty} from "antd";


const ClientMessagesList = ({decrementAmountOfUnrevisedMessages, jwt}) => {

    const [messages, setMessages] = useState([])
    
    useEffect(() => {

        const getMessages = async () => {
                const {data} = await getAllMessages(jwt);
                if (data) {
                    setMessages(data)
                }

        }
        getMessages()

    }, [jwt]);

    return (
        <div style={{position: "relative"}}>
            {messages.length === 0
                ?
                <Empty />
                :
                <div>
                    {messages.map((msg) =>
                            <ClientMessage
                                jwt={jwt}
                                decrementAmountOfUnrevisedMessages={decrementAmountOfUnrevisedMessages}
                                key={"cl-msg-" + msg.id}
                                message={msg}
                            />
                    )}
                </div>
            }
        </div>
    );
};

export default ClientMessagesList;