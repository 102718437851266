import React, {useEffect, useState} from 'react';
import {Button, Descriptions, Flex, Input, notification} from "antd";
import './GoodPropertiesList.css'
import GoodProperty from "../GoodProperty";
import {addNewPropertyByGoodId, deletePropertyByNameAndGoodId} from "../../../API/services/GoodService";
import useInput from "../../../hooks/useInput";
import {checkPermission} from "../../../API/services/AuthService";
import {useAuth0} from "@auth0/auth0-react";

const GoodPropertiesList = ({properties, setProperties, goodId}) => {

    const [previousFoundProperty, setPreviousFoundProperty] = useState(null)
    const propertyName = useInput(null);
    const propertyValue = useInput(null);
    const [api, contextHolder] = notification.useNotification();
    const {getAccessTokenSilently, isAuthenticated} = useAuth0()
    const [jwt, setJwt] = useState(null)

    useEffect(() => {
        const getJwt = async () => {
            const token = await getAccessTokenSilently();
            setJwt(token)
        }
        if (isAuthenticated) {
            getJwt()
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    const onAddProperty = async ()  => {
        propertyValue.onChange(null)
        propertyName.onChange(null)
        let name = propertyName.value;
        let value = propertyValue.value
        let data = new FormData();
        data.append("name", name)
        data.append("value", value)
        data.append("id", goodId)

        const accessToken = await getAccessTokenSilently();
        const {error} = await addNewPropertyByGoodId(accessToken, goodId, data)

        if (error) {
            api.error({message: `Error when added property.`})
        } else {
            api.success({message: `Property with name '${name}' has been added.`})
            properties.push([name, value])
            setProperties(properties)
        }
    }

    const onDeleteProperty = async (name) => {
        const {error} = await deletePropertyByNameAndGoodId()

        if (error) {
            api.error({message: `Error delete property.`})
        } else {
            api.success({message: `Property with name '${name}' has been deleted.`})
        }
        setProperties(properties.filter(e => e[0] !== name))
    }



    const onSearch = (e) => {
        let value = e.target.value

        if (!value) {
            setNormal(previousFoundProperty)
            return
        }

        if (value.length > 0) {

            let found = properties.find((pr) => {
                return String(pr[0]).toLowerCase().includes(String(value).toLowerCase())
            })

            if (found !== undefined) {
                let propName = document.getElementById("pr-name-" + found[0]).parentNode.parentNode
                if (previousFoundProperty !== null)  setNormal(previousFoundProperty)
                setSelected(propName)
                setPreviousFoundProperty(propName)
            }

        }
    }

    function setNormal(elem) {
        if (elem !== null) {
            elem.style.border = "none"
        }
    }

    function setSelected(elem) {
        if (elem !== null) {
            elem.style.border = "dashed red 2px"
        }
    }

    return (
        <>
            {contextHolder}
            {properties.length > 0
                ?
                <div className={"propertiesListWrapper"}>

                    <div className={"findPropertyInputWrapper"}>
                        <Input onChange={onSearch} placeholder="Search property..."
                               className={"findPropertyInput"}
                               bordered={false} />
                    </div>

                    <Descriptions bordered
                                  contentStyle={{color: "white", backgroundColor: "#2f2f2f"}}
                                  labelStyle={{backgroundColor: "#0a0a0a", color: "white"}}
                                  title={false}
                    >
                        {properties.map((entry)=>
                            <Descriptions.Item style={{minWidth: 50}}
                                key={entry[0]}
                                label={<span style={{minWidth: 50}} id={"pr-name-" + entry[0]}>{entry[0]}</span>}
                            >
                                <GoodProperty jwt={jwt}
                                    onDeleteProperty={onDeleteProperty}
                                    properties={properties}
                                    name={entry[0]}
                                    value={entry[1]}
                                />
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </div>
                :
                <></>
            }

            {checkPermission(jwt, "manage:goods")
                ?
                <Flex gap={5} vertical={true} style={{maxWidth: 300, marginTop: 30}}>
                    <Input {...propertyName} addonBefore={<span style={{color: "white"}}>Name</span>}  />
                    <Input {...propertyValue} addonBefore={<span style={{color: "white"}}>Value</span>}  />
                    <Button onClick={onAddProperty}>Add</Button>
                </Flex>
                :
                <></>
            }
        </>

    );
};

export default GoodPropertiesList;