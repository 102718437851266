import {jwtDecode} from "jwt-decode";


export const checkPermission = (token, permission) => {
    if (token !== null) {
        const  has = jwtDecode(token).permissions.indexOf(permission) !== -1
        return has;
    } else {
        return false
    }
}