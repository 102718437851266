import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/plots';
import {Slider} from "antd";
import {getDataForChart, getMaxPriceOfGoods} from "../../API/services/ChartService";

const Chart = ({setPriceRange, priceRange, onAfterChangePriceRange, setMaxPrice, maxPrice}) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const [pricesWithAmount] = await getDataForChart();
            const [maxPriceJson] = await getMaxPriceOfGoods()

            if (pricesWithAmount && maxPriceJson) {
                let max = maxPriceJson.maxPrice
                let arr = [{price: 0, amount: 0},...pricesWithAmount, {price: max, amount: 0}]
                setMaxPrice(max)
                setPriceRange([0, max])
                setData(arr)
            }
        }
        getData()
    }, [setMaxPrice, setPriceRange, setData]);


    const config = {
        data,
        padding: 0,
        xField: 'price',
        yField: 'amount',
        xAxis: false,
        yAxis: false,
        tooltip: false
    };

    return (
        <div style={{marginTop: 20, width: "auto"}}>
            <Line height={200}  {...config} />
            <Slider style={{margin: "0 20px", width: "auto"}}
                    onAfterChange={onAfterChangePriceRange}
                    onChange={setPriceRange}
                    value={[priceRange[0], priceRange[1]]}
                    range
                    defaultValue={[0, maxPrice]}
                    step={1}
                    max={maxPrice}
            />
        </div>
    );
};

export default Chart
