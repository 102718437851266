import React, {useContext, useEffect, useState} from 'react';
import {Button, Flex, Input, Modal, notification} from "antd";
import {EditOutlined} from "@ant-design/icons";
import useModal from "../../hooks/useModal";
import useInput from "../../hooks/useInput";
import ImageUpload from "../../components/ManageGoods/ImageUpload";
import useUpload from "../../hooks/useUpload";
import {updateSiteInfo} from "../../API/services/SiteInfoService";
import {getImageUrl} from "../../API/services/ImagesService";
import {AuthContext} from "../../AuthContext";

const EditSiteInfo = ({siteInfo}) => {

    const [isEditGenOpen,showEditGenModal, , handleCloseEditGenModal] = useModal(false)
    const title = useInput(siteInfo.title)
    const [fileList, setFileList, onImagesChange, , getImagesIds] = useUpload();
    const [api, contextHolder] = notification.useNotification();
    const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true)
    const {jwt} = useContext(AuthContext)

    useEffect(() => {
        setFileList([
            {url: getImageUrl(siteInfo.logoId)}
        ])
    }, [siteInfo, setFileList]);

    useEffect(() => {
        setSubmitBtnDisabled(fileList.length === 0 || !title.value)
    }, [fileList, title]);

    const update = async () => {
        const {data, error} =  await updateSiteInfo(jwt, siteInfo)
        if (data) notification.success({message: "ok"})
        else console.error(error)
    }

    const onSubmit = () => {



        if (jwt) {
            console.log(getImagesIds())

            if (getImagesIds().length > 0) {
                siteInfo.logoId = getImagesIds()[0]
            }
            siteInfo.title = title.value
            console.log(siteInfo)
            update()
        } else  notification.warning({message: "not auth"})
    }

    return (
        <>
            {contextHolder}
            <Button
                type={"primary"}
                onClick={showEditGenModal}
                icon={<EditOutlined />}
            >Edit</Button>
            <Modal
                title="Edit"
                footer={
                    [
                        <Button key={"editBtn-1"} disabled={submitBtnDisabled} type={"primary"} onClick={onSubmit}>Update</Button>,

                        <Button key={"editBtn-2"} onClick={handleCloseEditGenModal}>Cancel</Button>
                    ]
            }
                open={isEditGenOpen}
                onCancel={handleCloseEditGenModal}
            >
                <Flex gap={20} vertical={true}>
                    <Input addonBefore={"Title"}  {...title}/>

                    <ImageUpload label={"Good images"}
                                 fileList={fileList}
                                 handleChange={onImagesChange}
                                 required={false}
                                 messageApi={api}
                    />
                </Flex>
            </Modal>
        </>

    );
};

export default EditSiteInfo;