import {callAndGetResult} from "./ExternalApiService";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getAllProducers = async () => {
    const config = {
        url: `${apiServerUrl}/api/public/producer/all`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };
    return callAndGetResult(config)
};


export const getProducerById = async (id) => {
    const config = {
        url: `${apiServerUrl}/api/producer/${id}`,
        method: "GET",
    };
    return callAndGetResult(config)
};

export const addNewProducer = async (data, jwt) => {
    const config = {
        url: `${apiServerUrl}/api/protected/api/producer/new`,
        method: "POST",
        data: data,
        headers: {
            Authorization: `Bearer ${jwt}`,
        },
    };
    return callAndGetResult(config)
};



export const deleteProducerById = async (accessToken, id) => {
    const config = {
        url: `${apiServerUrl}/api/protected/producer/delete?id=${id}`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return callAndGetResult(config)
};
