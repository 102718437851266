import {callExternalApi} from "./ExternalApiService";


const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getDataForChart = async () => {
    const config = {
        url: `${apiServerUrl}/api/public/good/allPricesWithAmount`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const { data, error } = await callExternalApi({ config });

    return [
        data || null,
        error,
    ];
};

export const getMaxPriceOfGoods = async () => {
    const config = {
        url: `${apiServerUrl}/api/public/good/maxPrice`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const { data, error } = await callExternalApi({ config });

    return [
        data || null,
        error,
    ]
};