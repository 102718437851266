import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import MainLoader from "./components/MainLoader/MainLoader";
import {Auth0ProviderWithNavigate} from "./Auth0ProviderWithNavigate";
import {BrowserRouter} from "react-router-dom";
import './i18n';
import {Provider} from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <BrowserRouter>
                <Auth0ProviderWithNavigate>
                    <Suspense fallback={<MainLoader/>}>
                        <App />
                    </Suspense>
                </Auth0ProviderWithNavigate>
            </BrowserRouter>
        </React.StrictMode>
    </Provider>

);

