import {callAndGetResult} from "./ExternalApiService";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getManufactureMachineById = async (id) => {
    const config = {
        url: `${apiServerUrl}/api/public/good/manufacture-machine?id=${id}`,
        method: "GET"
    };
    return callAndGetResult(config)
};

export const loadMoreGoods = async (catalogId, pageId, size) => {
    const config = {
        url: `${apiServerUrl}/api/public/good/all?pageId=${pageId}&pageSize=${size}` + (catalogId ? `&catalogName=${catalogId}` : ''),
        method: "GET"
    };
    return callAndGetResult(config)
};

export const addNewPropertyByGoodId = async (accessToken, id, body) => {
    const config = {
        url: `${apiServerUrl}/api/protected/good/${id}/property/new"`,
        method: "POST",
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
        },
        body: body
    };
    return callAndGetResult(config)
};

export const deletePropertyByNameAndGoodId = async (accessToken, id, name) => {
    const config = {
        url: `${apiServerUrl}/api/protected/${id}/property/delete?name=${name}"`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return callAndGetResult(config)
};

export const deleteGoodImageByIdAngGoodId = async (accessToken, imageId, goodId) => {
    const config = {
        url: `${apiServerUrl}/api/protected/image/${imageId}/delete?mmId=${goodId}"`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return callAndGetResult(config)
};

export const addNewManufactureMachine = async (accessToken, body) => {
    const config = {
        url: `${apiServerUrl}/api/protected/good/new`,
        method: "POST",
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
        },
        data: body
    };
    return callAndGetResult(config)
};


export const deleteGoodById = async (accessToken, id) => {
    const config = {
        url: `${apiServerUrl}/api/protected/good/${id}/delete`,
        method: "DELETE",
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    };
    return callAndGetResult(config)
};


export const updateGood = async (jwt, id, body) => {
    const config = {
        url: `${apiServerUrl}/api/protected/good/${id}/update`,
        method: "PUT",
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${jwt}`,
        },
        data: body
    };
    return callAndGetResult(config)
};


export const getAllManufactureMachinesWithFilters = async (params) => {
    const config = {
        url: `${apiServerUrl}/api/public/good/page`,
        method: "GET",
        params: params
    };
    return callAndGetResult(config)
};


export const getAllManufactureMachines = async (name) => {
    let url;
    if (name !== undefined && name !== null) {
        url =  `${apiServerUrl}/api/public/good/all?catalogName=${name}`
    } else url =  `${apiServerUrl}/api/public/good/all`
    const config = {
        url: url,
        method: "GET"
    };
    return callAndGetResult(config)
};
