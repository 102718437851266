import React, {useContext} from 'react';
import {Typography} from "antd";
import './EditableAboutUs.css'
import {AuthContext} from "../../AuthContext";
import {checkPermission} from "../../API/services/AuthService";


const { Paragraph } = Typography;

const EditableText = ({onChange, value, color}) => {

    const {jwt} = useContext(AuthContext)

    return checkPermission(jwt, "manage:site_info")
        ?
        <Paragraph
            className={"editableAboutUs"}
            editable={{
                onChange: onChange,
                triggerType: 'text',
            }}
            style={{color: color}}
        >
            {value}
        </Paragraph>
        :
        <p style={{color: color}}>{value}</p>
};

export default EditableText;