import React from 'react';
import './MainLoader.css'
const MainLoader = () => {
    return (
        <div className={"wrapper"}>
            <span className="loader"></span>
        </div>
    );
};

export default MainLoader;