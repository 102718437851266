import React from 'react';
import {Button, Flex} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {checkPermission} from "../../API/services/AuthService";

const GoodProperty = ({name, value, onDeleteProperty, jwt}) => {

    const onDelete = () => {
        onDeleteProperty(name)
    }

    return (
            <Flex key={'pr-' + name} justify={"space-between"}>
                <span id={'pr-value-' + value} key={'vl-' + value}>{value}</span>

                {checkPermission(jwt, "manage:goods")
                    ?
                    <Button
                        onClick={onDelete}
                        className={"deletePropBtn"}
                        style={{display: "inline"}}
                        icon={<DeleteOutlined style={{fontSize: 20}} />}
                    ></Button>
                    :
                    <></>
                }

            </Flex>
    );
};

export default GoodProperty;