import React, {useEffect, useState} from 'react';
import './ProducerInfo.css'
import {useNavigate, useParams} from "react-router-dom";
import {deleteProducerById, getProducerById} from "../../API/services/ProducerService";
import {Avatar, Button, Descriptions, Flex, Image, notification, Skeleton} from "antd";
import Link from "antd/lib/typography/Link";
import {DeleteOutlined, EditOutlined, LeftOutlined} from "@ant-design/icons";
import EditProducer from "./EditProducer";
import {getImageUrl} from "../../API/services/ImagesService";
import {useAuth0} from "@auth0/auth0-react";
import {checkPermission} from "../../API/services/AuthService";

const ProducerInfo = () => {

    const params = useParams()
    const [producer, setProducer] = useState(null);
    const navigate = useNavigate()
    const [api, contextHolder] = notification.useNotification();
    const {getAccessTokenSilently, isAuthenticated} = useAuth0()
    const [jwt, setJwt] = useState(null)

    useEffect(() => {
        const getJwt = async () => {
            const token = await getAccessTokenSilently();
            setJwt(token)
        }

        if (isAuthenticated) {
            getJwt()
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    useEffect(() => {
        const getProducer = async () => {
            const {data, error} = await getProducerById(params.id)
            if (data) setProducer(data)
            if (error) throw new Error("Can't get producer by id.")
        }

        getProducer()
    }, [params]);

    const onDelete = async () => {
        const { error} = await deleteProducerById(jwt)
        if (error) throw new Error("Can't delete producer.")
    }

    return (
        <div className={"producerInfoWrapper"}>
            {contextHolder}
            <div className={" customContainer"}>
                <Flex align={"center"} justify={"space-between"}>
                    <div onClick={() => navigate(-1)} style={{cursor: "pointer", color: "white", fontSize: 20}} className={"backButton"}>
                        <LeftOutlined className={"backArrow"} />
                        <span>Back</span>
                    </div>
                    {checkPermission(jwt, "manage:producers")
                        ?
                        <div>
                            <EditProducer api={api} icon={<EditOutlined />} btnText={"Edit"} producer={producer}/>
                            <Button icon={<DeleteOutlined />} onClick={onDelete}>Delete</Button>
                        </div>
                        :
                        <></>
                    }
                </Flex>

                {producer === null
                    ?
                    <Skeleton active />
                    :

                    <Flex vertical={false} justify={"center"}  wrap={"wrap"}>
                        <div style={{color: "white", maxWidth: 500, marginTop: 30}}>
                            <Avatar shape="square" size={64} icon={<img src={getImageUrl(producer.logotypeId)} alt=""/>} />
                            <h3>{producer.name}</h3>
                            <p>{producer.description}</p>

                            <Descriptions title={false} labelStyle={{color: "white"}}>
                                <Descriptions.Item label="Website"><Link>{producer.websiteUrl}</Link></Descriptions.Item>
                            </Descriptions>
                        </div>

                        <Image.PreviewGroup>
                            <Flex  style={{margin: "30px 5px"}}  wrap={"wrap"} gap={20} justify={"center"} vertical={false} >
                                {producer.images.map((img) =>
                                    img !== null
                                        ?
                                        <Image key={"2" + img} src={getImageUrl(img)}/>
                                        :
                                        <></>
                                )}
                            </Flex>
                        </Image.PreviewGroup>
                    </Flex>
                }
            </div>
        </div>

    );
};

export default ProducerInfo;