import React from 'react';
import {Flex, Menu, Skeleton} from "antd";
import '../../../i18n'
import Translate from "../../../components/Translate";
import {useTranslation} from "react-i18next";


const CatalogFilter = ({catalogs, onChangeCatalog}) => {
    const {t} = useTranslation()

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }

    return (
        <Menu mode={"inline"} >
            {catalogs.map((catalog) =>
                <Menu.Item title={catalog.name} onClick={onChangeCatalog}  key={catalog.id}>

                    <Flex gap={5}>
                        <p style={{whiteSpace: "wrap!important"}}>
                            <Translate
                                skeleton={<Skeleton active style={{width: (getRandomArbitrary(50, 100) + "%")}} />}
                                t={t}
                                text={catalog.name}
                            />
                        </p>
                        <span style={{color: "#0000005C"}}>({catalog.count})</span>
                    </Flex>

                </Menu.Item>
            )}
        </Menu>
    );
};

export default CatalogFilter;