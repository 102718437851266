import {useState} from "react";


export default function useInput(initialValue) {

    const [value, setValue] = useState(initialValue)

    const onChange = (e) => {
        if (e === null) {
            setValue(e)
            return
        }
        if (typeof e === 'object') {
            if (e.target.type === 'file') {
                setValue(e.target.files[0])
            } else {
                setValue(e.target.value)
            }
        } else {
            setValue(e)
        }
    }

    return {value, onChange};

}