import React from 'react';
import {Flex} from "antd";

const AboutCompany = () => {
    return (
        <Flex style={{minHeight: "100vh", backgroundColor: "#212020"}} justify={"center"} >
            <Flex vertical style={{maxWidth: "90vw", marginTop: 100, color: "white", fontSize: 16}}>
                <p style={{color: "white"}}>
                    Hi!
                    We've been sell goods from Aliexpress and others platforms
                </p>
                <p>Your visits is inspire us</p>
                <q>The golden rule for every business is this: Put yourself in your customer's place</q>
                <p style={{textAlign: "end", color: "#706b6b"}}>The Goods times team.</p>
            </Flex>

        </Flex>
    );
};

export default AboutCompany;