import React from 'react';
import {LeftOutlined} from "@ant-design/icons";
import {getImageUrl} from "../../API/services/ImagesService";
import Translate from "../../components/Translate";
import {Flex, Image, Typography} from "antd";
import phone from "../../assets/img/phone.svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import './GoodInfoPage.css'

const GoodInfo = ({good, siteInfo}) => {
    const { Paragraph } = Typography;
    const navigate = useNavigate()
    const {t} = useTranslation()
    console.log(siteInfo)
    return (
        <div className="goodPriceAndMainImage">
            <div style={{maxWidth: 900}}>
                <div onClick={() => navigate(-1)} style={{cursor: "pointer"}} className={"backButton"}>
                    <LeftOutlined style={{fontSize: 30}} className={"backArrow"} />
                    <span>{ t('back') }</span>
                </div>
                <a id={"scrollTopPosition"} style={{display: "block"}} href={"/producer"}>
                    <img
                        style={{height: 50}}
                        src={getImageUrl(good.producer.logotypeId)}
                        alt="producer logo"
                    />
                </a>
                <span className={"goodProps"} style={{fontSize: 27, marginTop: 10, maxWidth: 500}}>
                                <Translate t={t} text={good.name} />
                            </span>
                <span className={"goodProps"} style={{fontSize: 18, marginTop: 8}}>{good.serialNumber}</span>

                <Flex style={{fontSize: 18, marginTop: 40}} gap={15} wrap={"wrap"}>
                    <span>{ t('ContactSalesTeam') }</span>
                    <Flex gap={5} style={{position: "relative"}}  wrap={ "wrap"} >
                        <img style={{position: "relative", bottom: -5}} height="20" src={phone} alt={"phone"}/>
                        <Flex gap={5} align={"center"} justify={"center"}>
                            <Paragraph style={{color: "white", fontSize: 18}} >{ t('call') }</Paragraph>
                            <Paragraph style={{color: "white", fontSize: 18}} copyable>{siteInfo.contacts.primaryPhone}</Paragraph>
                        </Flex>
                    </Flex>
                </Flex>
            </div>

            <div>
                <Image.PreviewGroup>
                    <Image style={{maxHeight: 553}} src={getImageUrl(good.images[0])}/>
                    {good.images.slice(1).map((img) =>
                        img !== null
                            ?
                            <Image key={"imageN" + img} style={{display: "none"}} src={getImageUrl(img)}/>
                            :
                            <></>
                    )}
                </Image.PreviewGroup>
            </div>
        </div>

    );
};

export default GoodInfo;