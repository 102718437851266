import axios from "axios";

export const callExternalApi = async (options, body) => {
  try {
    const response = await axios(options.config, body);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const axiosError = error;

      const { response } = axiosError;

      let message = "http request failed";

      if (response && response.statusText) {
        message = response.statusText;
      }

      if (axiosError.message) {
        message = axiosError.message;
      }

      if (response && response.data && response.data.message) {
        message = response.data.message;
      }
      console.log("Axios Error: ", error)
      return {
        data: null,
        error: {
          message,
        },
      };
    }
    console.log("External Api Error: ", error)

    return {
      data: null,
      error: {
        message: error.message,
      },
    };
  }
};

export const callAndGetResult = async (config) => {
  const { data, error } = await callExternalApi({ config });
  return {
    data: data || null,
    error,
  };
}
