import React, {useContext, useEffect, useState} from 'react';
import {Button, Collapse, Flex, notification} from "antd";
import {
    deleteGoodById,
    getAllManufactureMachines,
    getAllManufactureMachinesWithFilters,
    loadMoreGoods
} from "../../API/services/GoodService";
import GoodsList from "../../components/GoodsList/GoodsList";
import './GoodsPage.css'
import {PriceSort} from "../../enums/PriceSort";
import GoodLoader from "../../components/GoodLoader/GoodLoader";
import {FilterFilled, FilterOutlined, PlusOutlined} from "@ant-design/icons";
import ManageGoodForm from "../../components/ManageGoods/ManageGoodForm";
import GoodUtil from "../../API/util/GoodUtil";
import FiltersList from "./FiltersList/FiltersList";
import PriceFilter from "./Filters/PriceFilter";
import CatalogFilter from "./Filters/CatalogFilter";
import {useNavigate, useParams} from "react-router-dom";
import {checkPermission} from "../../API/services/AuthService";
import {useTranslation} from "react-i18next";
import {AuthContext} from "../../AuthContext";
import {Helmet} from "react-helmet";
import Translate from "../../components/Translate";
import {useDispatch, useSelector} from "react-redux";
import {setDeleteGoodsId, setGoodsList, setTotalItems} from "../../features/goods/goodsSlice";
import {setCurrentCatalog} from "../../features/catalogs/catalogsSlice";


const GoodsPage = () => {
    const goodsList = useSelector((state) => state.goods.goodsList)
    const deleteGoodsId = useSelector((state) => state.goods.deleteGoodsId)
    const updateGoodsId = useSelector((state) => state.goods.updateGoodsId)
    const totalItems = useSelector((state) => state.goods.totalItems)
    const currentCatalog = useSelector((state) => state.catalogs.currentCatalog)
    const dispatch = useDispatch()

    const nav = useNavigate()
    const [searchLoading, setSearchLoading] = useState(false)
    const [maxPrice, setMaxPrice] = useState(0)
    const [priceRange, setPriceRange] = useState([0, 0])

    const [selectedProducers, setSelectedProducers] = useState(new Map())
    const [selectedPriceSort, setSelectedPriceSort] = useState(PriceSort.HigherFirst)
    const catalogsList = useSelector((state) => state.catalogs.catalogsList)
    const {t} = useTranslation()
    const {jwt} = useContext(AuthContext)
    const {catalogName} = useParams()

    useEffect(() => {
        if (catalogName !== undefined) {
            if (catalogName !== currentCatalog) {
                dispatch(setCurrentCatalog(catalogName))
                getManufactureMachines(catalogName)
            }
        } else {
            if (goodsList.length > 0) {
                setPriceRange([0, 0])
                dispatch(setCurrentCatalog(null))
                getManufactureMachines()
            }
        }
    }, [catalogName]);

    useEffect(() => {
        let position = sessionStorage.getItem("position-good-id")
        if (position !== undefined) {
            const element = document.getElementById(position);
            if (element) {
                element.scrollIntoView();
                sessionStorage.removeItem("position-good-id")
            }
        }
    });

    const getManufactureMachines = async (catalog) => {
        setSearchLoading(true)
        const {data} = await getAllManufactureMachines(catalog)
        dispatch(setGoodsList(data.goodsList))
        dispatch(setTotalItems(data.totalItems))
        setSearchLoading(false)
    }


    useEffect(() => {
        if (goodsList.length === 0) {
            getManufactureMachines(catalogName)
        }
    }, []);


    const onPriceFilterChange = (val) => {
        setSelectedPriceSort(val)
        const copy = [...goodsList];
        const sorted = copy.sort(function (a, b)  {
            const val1 = Number(a.price)
            const val2 = Number(b.price)
            if (val1 === val2) return 0;
            if (val1 < val2) return val === PriceSort.HigherFirst ? 1 : -1
            else return val === PriceSort.HigherFirst ? -1 : 1
        })
        dispatch(setGoodsList(sorted))
    }

    async function onFiltersChange(producerIds, catalogName, priceRangeArr) {
        const params = {
            pageSize: 20,
            pageId: 0,
            catalogName: catalogName,
            priceFrom: priceRangeArr[0],
            priceTo: priceRangeArr[1]
        }

        setSearchLoading(true)
        const {data} = await getAllManufactureMachinesWithFilters(params)
        setSearchLoading(false)

        if (data) {
            dispatch(setGoodsList(GoodUtil.sortGood(selectedPriceSort, data.goodsList)))
            dispatch(setTotalItems(data.totalItems))
        }
    }


    const onAfterChangePriceRange = (newRange) => {
        onFiltersChange(selectedProducers, currentCatalog, newRange)
    }

    const onChangeFromPrice = (val) => {
        let newRange = [val, priceRange[1]]
        setPriceRange(newRange)
        onFiltersChange(selectedProducers, currentCatalog, newRange)
    }

    const onChangeToPrice = (val) => {
        let newRange = [priceRange[0], val]
        setPriceRange(newRange)
        onFiltersChange(selectedProducers, currentCatalog, newRange)
    }

    const filterGoodsListById = (id) => {
        const res = goodsList.filter((e) => e.id !== id)
        dispatch(setGoodsList(res))
    }

    useEffect(() => {
        if (deleteGoodsId !== null) {
            deleteGood(deleteGoodsId)
            dispatch(setDeleteGoodsId(null))
        }
    }, [deleteGoodsId]);

    useEffect(() => {
        if (updateGoodsId !== null) {
            setIsUpdateGoodsModalOpen(true)
        }

    }, [updateGoodsId]);

    const deleteGood =  async (goodId) => {
        const {error, data } = await deleteGoodById(jwt, goodId)
        if (data) {
            notification.success({message : <Translate t={t} text={"The goods was deleted successfully!"}/>})
            filterGoodsListById(goodId)
        }
        if (error) {
            notification.error({message : <Translate t={t} text={"Error when deleting the goods"}/>})
            throw error;
        }
    }


    const onChangeCatalog = (catalog) => {
        dispatch(setCurrentCatalog(catalog.item.props.title))
        onFiltersChange(selectedProducers, catalog.item.props.title, priceRange)
    }

    const items = [
        {
            key: '1',
            label: t('byPrice'),
            children:   <PriceFilter
                maxPrice={maxPrice}
                setMaxPrice={setMaxPrice}
                selectedPriceSort={selectedPriceSort}
                onChangeFromPrice={onChangeFromPrice}
                onChangeToPrice={onChangeToPrice}
                onPriceFilterChange={onPriceFilterChange}
                setPriceRange={setPriceRange}
                priceRange={priceRange}
                onAfterChangePriceRange={onAfterChangePriceRange}
            />,
        },
        {
            key: '2',
            label: t('byMachineType'),
            children:  <CatalogFilter
                catalogs={catalogsList}
                onChangeCatalog={onChangeCatalog}
            />,
        }
    ];


    const [filtersActivated, setFiltersActivated] = useState(false)
    const goodsWrapper = document.getElementsByClassName('goods')[0]

    const changeFilterState = () => {

        if (filtersActivated) {
            if (goodsWrapper) goodsWrapper.classList.remove("margin-left-20")
            setFiltersActivated(false)
        } else {
            if (goodsWrapper) goodsWrapper.classList.add("margin-left-20")
            setFiltersActivated(true)
        }
    }

    const onClearCatalogFilter = () =>  {
        getManufactureMachines()
        dispatch(setCurrentCatalog(null))
        nav("/all")
    }

    const [isLoading, setIsLoading] = useState(false)
    const [pageId, setPageId] = useState(0)

    const onLoadMore = async (id) => {
        setIsLoading(true)
        const {data} = await loadMoreGoods(currentCatalog, id, 20)
        if (data) {
            dispatch(setGoodsList([...goodsList, ...data.goodsList]))
            setIsLoading(false)
        }
    }


    useEffect(() => {
        if (pageId !== 0) {
            onLoadMore(pageId)
        }
    }, [pageId]);

    const onLoadMoreBtnClick = () => {
        setPageId(pageId + 1)
    }


    const [isNewGoodsModalOpen, setIsNewGoodsModalOpen] = useState(false)
    const [isUpdateGoodsModalOpen, setIsUpdateGoodsModalOpen] = useState(false)


    return (
        <div className="content customContainer goodsPage">
            {currentCatalog !== null ?
                <Helmet>
                    <title>{currentCatalog}</title>
                    <meta name="description" content={ "In this page you can see " + currentCatalog}/>
                    <meta name="keywords" content={[currentCatalog, currentCatalog.split(' ')].join(' ')} />
                    <meta property="og:title" content={currentCatalog} />
                    <meta property="og:description" content={"In this page you can see " + currentCatalog} />
                    <meta property="og:url" content={"https://the-goods-times.store/catalog/" +  currentCatalog}  />
                </Helmet>
                :
                <Helmet>
                    <title>The Goods times</title>
                    <meta name='description' content='Goods store'/>
                    <meta name='keywords' content='The Goods times, aliexpress goods'/>
                    <meta name='url' content='https://the-goods-times.store'/>

                    <meta name='og:title' content='We have the best goods for your attention'/>
                    <meta name='og:type' content='Goods store'/>
                    <meta name='og:url' content='https://the-goods-times.store'/>
                    <meta name='og:site_name' content='The Goods times'/>
                    <meta name='og:description' content='Goods from internet'/>
                    <meta name='og:image' content='https://paper-manufacture.miloverada.gov.ua:6060/api/upload/image/65d507a42131696af63f508e'/>
                </Helmet>

            }

            {checkPermission(jwt, "manage:goods") &&
                <Flex style={{margin: 10}}>
                    <ManageGoodForm btnText={"Додати товар"}
                                    icon={<PlusOutlined/>}
                                    isModalOpen={isNewGoodsModalOpen}
                                    setIsModalOpen={setIsNewGoodsModalOpen}
                    />
                    <ManageGoodForm edit
                                    btnText={"Оновити"}
                                    isModalOpen={isUpdateGoodsModalOpen}
                                    setIsModalOpen={setIsUpdateGoodsModalOpen}
                    />
                </Flex>
            }

            <div className="goodsListWrapper">
                {filtersActivated &&
                    <div className="filtersWrapper">
                        <div className="catalogFiltersWrapper">
                            <h5 className="filtersWrapperTitle">{ t("filters") }</h5>
                            <Collapse ghost items={items}/>
                        </div>
                    </div>
                }

                <Flex gap={10} vertical={true} style={{width: "100%"}}>
                    <div className={"filtersMargin"}>
                        <Flex justify={"space-between"}>
                            <h3>{currentCatalog === null
                                ?
                                <span style={{fontWeight: 600}}>{ t('AllProducts') }</span>
                                :
                                <Translate t={t} text={currentCatalog}/>
                            }
                            </h3>

                            <Flex justify={"space-between"} className={"filterBtnWrapper"} align={"center"} >
                                {filtersActivated
                                    ?
                                    <FilterFilled onClick={changeFilterState} className={"filterBtn"} />
                                    :
                                    <FilterOutlined onClick={changeFilterState}  className={"filterBtn"} />
                                }
                            </Flex>
                        </Flex>

                        <Flex align={"center"} wrap={"wrap"} gap={20}>
                            <div className={"nonSelect filterResults"}>
                                <span style={{fontWeight: 700}}>{t('Results')}: {totalItems && <span>{totalItems}</span> }</span>
                            </div>

                            <FiltersList
                                onClearCatalogFilter={onClearCatalogFilter}
                                currentCatalog={currentCatalog}
                                maxPrice={maxPrice}
                                setPriceRange={setPriceRange}
                                setSelectedProducers={setSelectedProducers}
                                producers={selectedProducers}
                                priceFrom={priceRange[0]}
                                priceTo={priceRange[1]}
                            />

                        </Flex>
                    </div>

                    {searchLoading
                        ?
                        <GoodLoader/>
                        :
                        <GoodsList goodsList={goodsList}/>
                    }

                    <Flex justify={"center"} style={{marginTop: 10}}>
                        <Button loading={isLoading} onClick={onLoadMoreBtnClick}><Translate t={t} text={"Load more"}/> {(totalItems && goodsList) && <span>({totalItems - goodsList.length})</span> }</Button>
                    </Flex>
                </Flex>
            </div>
        </div>
    );
};

export default GoodsPage;