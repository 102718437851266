import React from 'react';
import './GoodsList.css'
import Good from "../Good/Good";
import {Empty, Flex} from "antd";

const GoodsList = ({goodsList}) => {

    return (
        <div className="goods" style={{display: goodsList.length > 0 ? "grid" : "flex"}}>
            {goodsList.length > 0
                ?
                goodsList.map((good) =>
                    <Good key={'goodWrapper-' + good.id} good={good}/>
                )
                :
                <Flex align={"center"} justify={"center"} style={{width: "100%", height: "40vh"}}>
                    <Empty
                        description={"There are no goods; perhaps they haven't been added yet. Please check the goods filters."}/>
                </Flex>
            }
        </div>
    );
};

export default GoodsList;