import axios from "axios";
import {BACK_HOST} from "./Config";
import {callExternalApi} from "./ExternalApiService";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const setRevisedById = async (accessToken, messageId) => {
    const config = {
        url: `${apiServerUrl}/api/protected/message/setRevised/${messageId}`,
        method: "POST",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        }
    };

    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error,
    };
};


export const saveClientMessage = async (body) => {
    body['isRevised'] = false;
    const config = {
        url: `${apiServerUrl}/api/message/new`,
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        data: body
    };

    const { data, error } = await callExternalApi({ config });
    return {
        data: data || null,
        error,
    };
};


export const getAmountOfUnrevisedMessages = async () => {
    const config = {
        url: `${apiServerUrl}/api/message/getAmountOfUnrevisedMessages`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

export const getAllMessages = async (accessToken) => {
    const config = {
        url: `${apiServerUrl}/api/protected/messages/all`,
        method: "GET",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};