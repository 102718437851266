import React, {useEffect, useState} from "react";
import Header from "./components/Header/Header";
import './App.css'
import Footer from "./components/Footer/Footer";
import {Navigate, Route, Routes} from "react-router-dom";
import GoodsPage from "./pages/AllGoodsPage/GoodsPage";
import GoodInfoPage from "./pages/GoodInfoPage/GoodInfoPage";
import ProducerInfo from "./pages/ProducerInfo/ProducerInfo";
import {App as AntdApp, Layout} from "antd";
import {Helmet} from "react-helmet";
import OnCreatedClientMessage from "./pages/OnCreatedClientMessage/OnCreatedClientMessage";
import {getSiteInfo} from "./API/services/SiteInfoService";
import {useAuth0} from "@auth0/auth0-react";
import {getImageUrl} from "./API/services/ImagesService";
import {AuthContext} from "./AuthContext";
import AboutCompany from "./pages/AboutCompanyPage/AboutCompany";


function App() {
    const [siteInfo, setSiteInfo] = useState({})

    useEffect(() => {
        const getSiteInformation = async () => {
            const { data } = await getSiteInfo();
            if (data) setSiteInfo(data)
        }
        getSiteInformation()
    }, []);

    const {getAccessTokenSilently, isAuthenticated} = useAuth0()
    const [jwt, setJwt] = useState(null)

    useEffect(() => {
        const getJwt = async () => {
            const token = await getAccessTokenSilently();
            setJwt(token)
        }

        if (isAuthenticated) {
            getJwt()
        }
    }, [getAccessTokenSilently, isAuthenticated]);

    return (
        <AntdApp>
            <div className="App">
                <Layout>
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>{siteInfo.title}</title>

                        {siteInfo.logoId !== undefined &&
                            <link rel="icon" type="image/x-icon" href={getImageUrl(siteInfo.logoId)}/>
                        }
                    </Helmet>
                    <AuthContext.Provider value={{jwt, setJwt}}>
                        <Header siteInfo={siteInfo}/>
                        <Routes>
                            <Route exact path="/all" element={<GoodsPage/>}/>
                            <Route path={"/catalog/:catalogName"} element={<GoodsPage/>}/>
                            <Route path={"/aboutCompany"} element={<AboutCompany/>}/>
                            <Route path={"/good/:id"} element={<GoodInfoPage siteInfo={siteInfo}/>}/>
                            <Route path={"/producer/:id"} element={<ProducerInfo/>}/>
                            <Route path={"/message/:id"} element={<OnCreatedClientMessage/>}/>
                            <Route
                                path="/"
                                element={<Navigate to="/all"/>}
                            />
                        </Routes>
                        <Footer siteInfo={siteInfo}/>
                    </AuthContext.Provider>
                </Layout>
            </div>
        </AntdApp>

    );
}

export default App;
