import React from 'react';
import {Flex, InputNumber, Segmented, Space} from "antd";
import Chart from "../../../components/Chart/Chart";
import {useTranslation} from "react-i18next";
import {PriceSort} from "../../../enums/PriceSort";


const PriceFilter = ({priceRange, setPriceRange, onAfterChangePriceRange, onChangeFromPrice, onChangeToPrice, onPriceFilterChange, selectedPriceSort, setMaxPrice, maxPrice}) => {
    const {t} = useTranslation()

    return (
        <div className="catalogFilter">
            <div style={{width: "inherit"}} className="catalogFilterSubWrapper">
                <div className="tab priceFilterWrapper">
                    <div className={"priceFilterInputs"} >
                        <Space>
                            <InputNumber addonBefore={ t('from')}
                                         id="priceFrom"
                                         onChange={onChangeFromPrice}
                                         value={priceRange[0]}
                            />

                            <InputNumber addonAfter={ t('to')} id="priceTo"
                                         onChange={onChangeToPrice}
                                         value={priceRange[1]}
                                         type="text"
                            />
                        </Space>
                        <Flex justify={"center"} style={{marginTop: 10}}>
                            <Segmented onChange={onPriceFilterChange}
                                       value={selectedPriceSort}
                                       options={[ {label : t('higherFirst'), value: PriceSort.HigherFirst} , {label: t('cheaperFirst'), value : PriceSort.CheaperFirst} ]}
                            />
                        </Flex>
                    </div>

                    <Chart
                        maxPrice={maxPrice}
                        setMaxPrice={setMaxPrice}
                        setPriceRange={setPriceRange}
                        priceRange={priceRange}
                        onAfterChangePriceRange={onAfterChangePriceRange}
                    />
                </div>
            </div>
        </div>
    );
};

export default PriceFilter;