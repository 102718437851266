import React, {useEffect, useState} from 'react';
import {AutoComplete, Empty, Flex, Input} from "antd";
import {findByNameOrSerialNumber} from "../../../API/services/SearchService";
import {useLocation, useNavigate} from "react-router-dom";
import './Search.css'
import {SelectOutlined} from "@ant-design/icons";
import Condition from "../../Condition";
import Highlighter from "react-highlight-words";
import {useTranslation} from "react-i18next";

const SearchGoods = ({style, showModal, options, setOptions, onChange}) => {

    const nav = useNavigate()
    const location =useLocation()
    const {t} = useTranslation()
    const [formattedOptions, setFormattedOptions] = useState([])
    const [query, setQuery] = useState('')
    const [val, setVal] = useState('')
    useEffect(() => {
        onChange(query)
    }, [query, onChange]);

    useEffect(() => {
        function format(response) {
            return response.map((option) => {
                return {
                    key: option.id,
                    value: String(option.id),
                    label: (
                        <Flex gap={25} align={"center"} justify={"space-between"}>
                            <Highlighter
                                style={{overflow: "hidden", textOverflow: "ellipsis"}}
                                highlightClassName="YourHighlightClass"
                                searchWords={[query]}
                                autoEscape={true}
                                textToHighlight={option.name}
                            />
                            <span style={{fontWeight: "bold"}}>{option.serialNumber}</span>
                        </Flex>
                    )
                }
            })
        }

        setFormattedOptions(format(options))
    }, [options, query]);


    const empty = [{
        label: (
            <div>Not found</div>
        ),
        value: null
    }]

    const handleSearch = async (enteredQuery) => {
        console.log(enteredQuery)
        setQuery(enteredQuery)
        if (enteredQuery.length <= 255 && enteredQuery.length > 0) {
            const {data, error} = await findByNameOrSerialNumber(enteredQuery)
            console.log(data)

            if (data) {
                if (data.length > 0) {
                    setOptions(data)
                } else {
                    setFormattedOptions(empty)
                }
            }
            if (error) {
                throw new Error("Search error!")
            }
        } else {
            setFormattedOptions(empty)
        }
    };
    const onSelect = (value) => {
        if (value === null) return
        nav("/manufacture-machine/" + value)
        setVal('')
    };

    const change = (e) => {
        setVal(e)
    }

    return (
            <Flex className={"searchWrapper"} gap={5} style={style}>
                <AutoComplete
                    value={val}
                    onChange={change}
                    popupMatchSelectWidth={350}
                    style={{ width: 300, position: "relative", top: -5}}
                    options={formattedOptions}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                >
                    <Input.Search
                        className={"search"}
                        placeholder={t("search")}
                        enterButton />

                </AutoComplete>

                <Condition if={options.length > 0}
                    then={<SelectOutlined onClick={showModal} style={{fontSize: 20, cursor: "pointer"}} />}
                    elseNone
                />
            </Flex>
    );
};

export default SearchGoods;