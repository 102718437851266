import { createSlice } from '@reduxjs/toolkit'

export const catalogsSlice = createSlice({
    name: 'catalogs',
    initialState: {
        catalogsList: [],
        currentCatalog : null
    },
    reducers: {
        setCatalogsList: (state, action) => {
            state.catalogsList = action.payload
        },
        setCurrentCatalog : (state, action) => {
            state.currentCatalog = action.payload
        }
    },
})

export const {setCatalogsList, setCurrentCatalog} = catalogsSlice.actions

export default catalogsSlice.reducer