import React, {useContext, useEffect, useState} from 'react';
import {Button, Dropdown, Flex, Input, Modal, notification} from "antd";
import {useNavigate} from "react-router-dom";
import './HeaderNavbar.css'
import {DownOutlined} from "@ant-design/icons";
import {deleteCatalogById, getAllCatalogs, updateCatalog} from "../../../API/services/CatalogService";
import {useTranslation} from "react-i18next";
import Translate from "../../Translate";
import useInput from "../../../hooks/useInput";
import {AuthContext} from "../../../AuthContext";
import {useDispatch, useSelector} from "react-redux";
import {setCatalogsList} from "../../../features/catalogs/catalogsSlice";

const HeaderNavbar = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {jwt} = useContext(AuthContext)
    const catalogsList = useSelector((state) => state.catalogs.catalogsList)
    const dispatch = useDispatch()

    const seeAll = {
        key: 'seeAll',
        label:  <span className="link catalogItem" style={{color: "black"}} onClick={() => {navigate("/all")}}>  <Translate t={t} text={"See all goods"} /></span>,
    }

    useEffect(() => {
        const getCatalogs = async () => {
            const {data} = await getAllCatalogs();
            if (data) {
                dispatch(setCatalogsList(data))
            }
        }
        getCatalogs()
    }, []);



    function createItems(id) {
        return [
            {
                label: 'Перейменувати',
                key: 'rename-' + id,
            },
            {
                label: 'Видалити',
                key: 'delete-' + id,
                danger: true
            },
        ]
    }

    const [catalog, setCatalog] = useState()

    const onActionSelect = (e) => {
        const arr = e.key.split('-')
        const action = arr[0]
        const catalogId = arr[1]

        if (action === 'rename') {
            const catalog = catalogsList.find((c) => c.id === Number(catalogId))
            setCatalog(catalog)
            newName.onChange(catalog.name)
            setIsRenameCatalogModalOpen(true)
        }
        if (action === 'delete') {
            console.log("here")
            Modal.confirm({
                title : `Видалити каталог?`,
                okText : "Гаразд",
                cancelText : "Не буду",
                onOk : () => onDelete(catalogId),
            })
        }
    }

    const onDelete = async (id) => {
        if (jwt) {
            let formData = new FormData()
            formData.append("name", newName.value)
            const {data, error} = await deleteCatalogById(id, jwt)
            if (data) {
                notification.success({message: <Translate t={t} text={"The catalog was deleted successfully!"}/>})
                const  arr = catalogsList.filter((e) => e.id !== Number(id))
                dispatch(setCatalogsList([...arr]))

            }
            if (error) {
                notification.error({
                    message: <Translate t={t} text={"Error when delete the catalog"}/>,
                    description: <Translate t={t} text={error.message}/>,
                    duration: 5
                })
            }

        } else notification.warning({message: "not auth"})

    }

    const [isRenameCatalogModalOpen, setIsRenameCatalogModalOpen] = useState(false)
    const newName = useInput()
    const onSubmit = async () => {
        if (!newName.value) notification.warning({message: "Поле пусте"})

        if (jwt) {
            if (catalog) {

                let formData = new FormData()
                formData.append("name", newName.value)
                const {data, error} = await updateCatalog(formData, catalog.id, jwt)
                if (data) {
                    setIsLoading(true)
                    notification.success({message: <Translate t={t} text={"The catalog was updated successfully!"}/>})
                    setIsLoading(false)
                    const listCopy = [...catalogsList]

                    listCopy[listCopy.indexOf(catalog)] = data
                    dispatch(setCatalogsList([...listCopy]))
                    setCatalog(undefined)
                    newName.onChange()
                    setIsRenameCatalogModalOpen(false)
                }
                if (error) {
                    notification.error({
                        message : <Translate t={t} text={"Error when update the catalog"}/>,
                        description: <Translate t={t} text={error.message}/>,
                        duration : 5
                    })
                }
            } else console.error("catalog undef")

        } else  notification.warning({message: "not auth"})

    }
    const [isLoading, setIsLoading] = useState(false)


    const getItems = () => {
        let res = catalogsList.map((catalog) => {
            return {
                key: 'catalog-' + catalog.id,
                label:
                    <Dropdown menu={ {items : createItems(catalog.id), onClick: onActionSelect}} trigger={['contextMenu']}>
                        <span className="link catalogItem" onClick={() => navigate(`/catalog/${catalog.name}`)}> <Translate t={t} text={catalog.name}></Translate> </span>
                    </Dropdown>,
            }
        })
        return [seeAll, ...res]
    }

    const onModalClose = () => {
        setIsRenameCatalogModalOpen(false)
    }

    return (
        <div className={"headerItemWrapper"}>
            <Flex gap={20} className={"customContainer headerLinks"}>

                    <Dropdown trigger={['click']}
                        menu={{items: getItems()}}
                    >
                        <Flex align={"center"} gap={4} style={{color: "white"}}>
                            <span className={"headerLink link goodCatalog"}> { t("goodCatalog") } </span>
                            <DownOutlined />
                        </Flex>
                    </Dropdown>

                <span
                    onClick={() => navigate("/aboutCompany")}
                    className={"headerLink link aboutCompany"}>
                            { t("aboutCompany") }
                </span>
            </Flex>
            <Modal open={isRenameCatalogModalOpen}
                title={"Перейменувати каталог"}
                   onOk={onSubmit}
                   footer={[
                       <Button key={"renameCatalogCancel"} onClick={onModalClose}>Не хочу</Button>,
                       <Button key={"renameCatalogOk"} loading={isLoading} type={"primary"} onClick={onSubmit}>Перейменувати</Button>
                   ]}
                   onCancel={onModalClose}
            >
                <Input {...newName}/>

            </Modal>
        </div>
    );
};

export default HeaderNavbar;