import {callAndGetResult, callExternalApi} from "./ExternalApiService";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const getSiteInfo = async () => {
    const config = {
        url: `${apiServerUrl}/api/site/info`,
        method: "GET",
        headers: {
            "content-type": "application/json",
        },
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

export const updateSiteInfo = async (accessToken, body) => {
    const config = {
        url: `${apiServerUrl}/api/protected/site/info/update`,
        method: "PUT",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
        data: body
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

export const updateAboutUs = async (accessToken, body) => {
    const config = {
        url: `${apiServerUrl}/api/messages/protected/site/info/aboutUs/update`,
        method: "POST",
        headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
        },
        data: body
    };
    return callAndGetResult(config)
};
