import React from 'react';

const Condition = (props) => {
    return (
        props.if === true
                ?
                props.then
                :
                props.elseNone === true
                    ?
                    <></>
                    :
                    props.else
    );
};

export default Condition;