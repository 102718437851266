import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import './GoodInfoPage.css'
import {getManufactureMachineById} from "../../API/services/GoodService";
import {Flex, Image, Skeleton} from "antd";
import GoodPropertiesList from "./GoodPropertiesList/GoodPropertiesList";
import Translate from "../../components/Translate";
import {getImageUrl} from "../../API/services/ImagesService";
import {useTranslation} from "react-i18next";
import GoodInfo from "./GoodInfo";

const GoodInfoPage = ({siteInfo}) => {



    const params = useParams()
    const [good, setGood] = useState(null)
    const [properties, setProperties] = useState([])
    const {t} = useTranslation()

    useEffect(() => {
        const getGood = async () => {
            const {data} = await getManufactureMachineById(params.id);
            setGood(data)
            setProperties(Object.entries(data.properties))
        }
        getGood()
    }, [setGood, setProperties, params]);

    useEffect(() => {
        const element = document.getElementById("scrollTopPosition");
        if (element) {
            element.scrollIntoView();
        }
    });


    return (
        <div>
            {good != null
                ?
                <div className="content goodInfoPage">

                    <GoodInfo siteInfo={siteInfo} good={good}/>

                    <div className="goodInfoWrapper">
                        <div className={"goodInfoPriceWrapper"}>
                            <div style={{display: "flex"}}>
                                <div className={"goodInfoPriceWrapper2"}>
                                    <span>{ t('TheApproximatePrice') }: </span>
                                    <span>$</span>
                                    <span id="priceValue">{good.price}</span>
                                </div>
                            </div>
                        </div>

                        <Translate isHtml t={t} text={good.description} />

                        <GoodPropertiesList setProperties={setProperties} goodId={good.id} properties={properties}/>
                    </div>

                    <Image.PreviewGroup>
                        <Flex  style={{margin: "30px 5px"}}  wrap={"wrap"} gap={20} justify={"center"} vertical={false} >
                            {good.images.slice(1).map((img) =>
                                img !== null
                                    ?
                                    <Image key={"2" + img} src={getImageUrl(img)}/>
                                    :
                                    <></>
                            )}
                        </Flex>
                    </Image.PreviewGroup>
                </div>
                :
                <Skeleton active/>
            }
        </div>
    );
};

export default GoodInfoPage;