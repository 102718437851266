import React, {useState} from 'react';
import {Button, Flex, Form, Input, Modal} from "antd";
import useInput from "../../hooks/useInput";
import GoodService from "../../API/services/GoodService";
import axios from "axios";
import {BACK_HOST} from "../../API/services/Config";

const EditProducer = ({producer, api, icon, btnText}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const name = useInput(producer.name);
    const description = useInput(producer.description)
    const website = useInput(producer.websiteUrl)

    const onCancel = () => {
        setIsModalOpen(false)
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const onSubmit = () => {
        let data = new FormData()

        console.log(name.value, description.value, website.value)
        data.append("id", producer.id)
        data.append("name", name.value)
        data.append("description", description.value)
        data.append("websiteUrl", website.value)

        // GoodService.handleErrorOrPrintResponseStatus(axios.put(BACK_HOST  + "/producer/update", data), api)

        setIsModalOpen(false)
        window.location.reload(false);
    }

    return (
        <>
            <Modal
                title="Edit a producer"
                footer={false}
                open={isModalOpen}
                onCancel={onCancel}
            >

                <Form
                    initialValues={
                        {
                            name: name.value,
                            description: description.value,
                            website_url: website.value
                        }
                    }
                    onFinish={onSubmit}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{required: true, message: 'Please input a good name!'}]}
                    >
                        <Input {...name} />
                    </Form.Item>

                    <Form.Item
                        label="Description"
                        name="description"
                        rules={[{required: true, message: 'Please input a description!'}]}
                    >
                        <Input {...description} />
                    </Form.Item>

                    <Form.Item
                        label="Website url"
                        name="website_url"
                        rules={[{required: true, message: 'Please input a website url!'}]}
                    >
                        <Input {...website} />
                    </Form.Item>

                    <Flex vertical={false} justify={"flex-end"} gap={10}>
                        <Button onClick={onCancel}>Cancel</Button>

                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Flex>

                </Form>

            </Modal>
            <Button onClick={showModal} icon={icon}>{btnText}</Button>
        </>
    );
};

export default EditProducer;