import {callAndGetResult} from "./ExternalApiService";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;


export const findByNameOrSerialNumber = async (query) => {
    const config = {
        url: `${apiServerUrl}/api/public/good/manufacture-machine/search?q=${query}`,
        method: "GET",
    };

    return callAndGetResult(config)
};

