import { createSlice } from '@reduxjs/toolkit'

export const goodsSlice = createSlice({
    name: 'goods',
    initialState: {
        goodsList: [],
        updateGoodsId : null,
        deleteGoodsId : null,
        totalItems : null
    },
    reducers: {
        setGoodsList: (state, action) => {
            state.goodsList = action.payload
        },
        remove: (state) => {
            state.goodsList -= 1
        },
        setUpdateGoodsId : (state, action) => {
            state.updateGoodsId = action.payload
        },
        setDeleteGoodsId : (state, action) => {
            state.deleteGoodsId = action.payload
        },
        setTotalItems :  (state, action) => {
            state.totalItems = action.payload
        }
    },
})

export const { setGoodsList,
    remove,
    setUpdateGoodsId,
    setDeleteGoodsId,
    setTotalItems} = goodsSlice.actions

export default goodsSlice.reducer