import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {Button, Divider, Flex, Result} from "antd";
import {useTranslation} from "react-i18next";


const OnCreatedClientMessage = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()

    useEffect(() => {
        const element = document.getElementById("scrollTopPosition");
        if (element) {
            element.scrollIntoView();
        }
    });


    return (
        <Flex justify={"center"} align={"center"} style={{height: "100vh"}}>
            <Flex vertical={true} justify={"center"} align={"center"} style={{width: "90vw"}}>
                <h1 id={"scrollTopPosition"} style={{marginLeft: 40}} className={"nonSelect"}>{ t('MessageSentSuccessfully') }</h1>
                <Divider style={{height: 2, backgroundColor: "black", maxWidth: "90vw", marginTop: 0}}  />

                <Result
                    status="success"
                    title={t('WeReceivedMessage')}
                    subTitle={ t('Sincerely') }
                    extra={[
                        <Button onClick={() => navigate("/")} type="primary" key="console">
                            Ok
                        </Button>
                    ]}
                />

            </Flex>
        </Flex>
    );
};

export default OnCreatedClientMessage;