import {useState} from "react";
import {BACK_HOST} from "../API/services/Config";
import {delImage} from "../API/services/ImagesService";


export default function useUpload(initialValue) {

    const [fileList, setFileList] = useState(initialValue !== undefined ? toImg(initialValue) : []);


    function toImg(images) {
        let res = (images.map((img) => {
            return {
                key: img,
                url: BACK_HOST + "/upload/image/" + img
            }
        }))

        return res
    }


    const onCancelInput = async () => {
        for (let i = 0; i < fileList.length; i++) {
            if (fileList[i].response !== undefined) {
                const {data ,error} = await delImage(fileList[i].response)
                if (data) {
                    console.log("del img ok")
                }
                if (error) throw error
            } else {
                console.error('Image save response is undefined!')
            }
        }
        setFileList([])
    }

    const handleChange = (e) => {
        setFileList(e.fileList)
    };

    const getImagesIds = () => {
        return fileList.map((file) => {
            if (file.response !== undefined) return file.response
            else if (file.url !== undefined) return file.url
            else throw new Error("Can't get image url")
        })
    }

    return [fileList, setFileList, handleChange, onCancelInput, getImagesIds]
}

