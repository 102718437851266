import {useState} from "react";


export default function useSelectInput(initialValue) {

    const [value, setValue] = useState(initialValue)

    const onChange = (e) => {
        setValue(e)
    }

    return {value, onChange};

}