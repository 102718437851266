import React, {useEffect, useState} from 'react';
import {Skeleton} from "antd";
import TranslateService from "../API/services/TranslateService";
import {useTranslation} from "react-i18next";

const Translate = ({text, skeleton, isHtml}) => {

    const {i18n} = useTranslation()
    const [isLoading, setIsLoading] = useState(false);
    const [translated, setTranslated] = useState(text);

    const currentLang = i18n.language.substring(0,2);

    useEffect(() => {
        const translateText = (text) => {
            setIsLoading(true)
            TranslateService.translateText(currentLang, text).then((res) => {
                if (res.fromCache === undefined) {
                    TranslateService.translationMap.set(text + res.TargetLanguageCode, res.TranslatedText)
                }
                setTranslated(res.TranslatedText)
                setIsLoading(false)
            }, handleError)
        }

        translateText(text)
    }, [text, currentLang, i18n]);

    function handleError(e) {
        console.log("Translation error: " + e)
        throw new Error(e)
    }

    return (
        <>
            {isLoading
                ?
                skeleton !== undefined ? skeleton :  <Skeleton active />
                :
                !isHtml ? translated :  <div
                    style={{color: "white", fontSize: 16}}
                    dangerouslySetInnerHTML={{__html: translated}}  >
                </div>
            }
        </>
    );
};

export default Translate;