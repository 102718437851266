import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Badge, Button, Descriptions, Flex, Image, Modal, Tabs} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import useModal from "../../hooks/useModal";
import EditSiteInfo from "./EditSiteInfo";
import ClientMessagesList from "../../components/ClientMessages/ClientMessagesList";
import {getAmountOfUnrevisedMessages} from "../../API/services/ClientMessageService";
import {getImageUrl} from "../../API/services/ImagesService";
import {useAuth0} from "@auth0/auth0-react";
import {LogoutButton} from "../../components/buttons/LogoutButton";
import {TabPane} from "react-bootstrap";
import {checkPermission} from "../../API/services/AuthService";
import {LoginButton} from "../../components/buttons/LoginButton";
import {AuthContext} from "../../AuthContext";


const ManagePanel = ({siteInfo}) => {

    const [amountOfUnrevisedMessages, setAmountOfUnrevisedMessages] = useState(0)
    const {isAuthenticated} = useAuth0()
    const {jwt} = useContext(AuthContext)

    useEffect(() => {
        const getAmountOfMessages = async () => {
            const {data} = await getAmountOfUnrevisedMessages();

            if (data) {
                setAmountOfUnrevisedMessages(data)
            }
        }

        if (jwt) getAmountOfMessages()

    }, []);

    const decrementAmountOfUnrevisedMessages = useCallback(() => {
        setAmountOfUnrevisedMessages(amountOfUnrevisedMessages - 1);
    }, [amountOfUnrevisedMessages, setAmountOfUnrevisedMessages]);

    const [isManageModalOpen,showManageModal, handleOkManageModal, handleCloseManageModal] = useModal(false)
    const [modalWidth, setModalWidth] = useState(600)

    const onTabChanged = (key) => {
        if (key === "tab2") setModalWidth(800)
        else setModalWidth(600)
    }


    return (
        <>
            {checkPermission(jwt,"read:admin-messages")
                ?
                <Badge count={amountOfUnrevisedMessages} >
                    <SettingOutlined style={{fontSize: 27, color: "white"}} fill={"#f5222d"}  onClick={showManageModal}  />
                </Badge>
                :
                <SettingOutlined style={{fontSize: 27, color: "white"}} fill={"#f5222d"}  onClick={showManageModal}  />
            }


            <Modal
                width={modalWidth}
                title="Manage panel"
                footer={[<Button key={"okBtnManagePanel"} onClick={handleOkManageModal}>Ok</Button>]}
                open={isManageModalOpen}
                onCancel={handleCloseManageModal}
            >
                <Tabs
                    onChange={onTabChanged}
                >

                    {!isAuthenticated &&
                        <TabPane tab="Account" key="logIn">
                                <h4>Log in</h4>
                                <LoginButton redirectToCurrent text={"Log In"}/>
                        </TabPane>
                    }

                    <TabPane tab="Cookies" key="2">
                        <div>
                            <h4>Cookies</h4>
                            <Flex vertical gap={5}>
                                <Button style={{width: "fit-content"}} type={"primary"}>Clear all cookies</Button>
                                <Button style={{width: "fit-content"}} type={"primary"}>Disable cookies</Button>
                            </Flex>
                        </div>
                    </TabPane>

                    {checkPermission(jwt,"manage:site_info") &&
                            <TabPane tab="Site settings" key="Site">
                                <div>
                                    <Descriptions title={false}>
                                        <Descriptions.Item label="Title">{siteInfo.title}</Descriptions.Item>
                                        <Descriptions.Item label={"Logo"}>
                                            <Image
                                                width={200}
                                                src={getImageUrl(siteInfo.logoId)}
                                            />

                                        </Descriptions.Item>
                                    </Descriptions>
                                    <EditSiteInfo siteInfo={siteInfo}/>
                                </div>
                            </TabPane>
                    }

                    {isAuthenticated &&
                        <TabPane tab="Account" key="Account">
                            <div>
                                <h3 style={{marginTop: 5}}>Account settings</h3>
                                <Flex gap={20} style={{marginTop: 40}}>
                                    <LogoutButton/>
                                    <LoginButton redirectToCurrent text={"Log in from another account"}/>
                                </Flex>
                            </div>
                        </TabPane>
                    }


                    {checkPermission(jwt,"read:admin-messages") &&
                        <TabPane tab="Client messages" key="Client">
                            <ClientMessagesList jwt={jwt}
                                                decrementAmountOfUnrevisedMessages={decrementAmountOfUnrevisedMessages}
                            />

                        </TabPane>
                    }
                </Tabs>
            </Modal>
        </>

    );
};

export default ManagePanel;