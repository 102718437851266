import {BACK_HOST} from "./Config";
import {callExternalApi} from "./ExternalApiService";
import axios from "axios";
const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

const GET_IMAGE_ENDPOINT = apiServerUrl + "/api/upload/image/"

export const getImageUrl = (imageId) => {
    if (imageId === undefined) return "/"
    return GET_IMAGE_ENDPOINT + imageId
}

export const mapImageForAntDesign = (images) => {
    if (images === undefined ) return []

    return images
        .filter((img) => img !== undefined)
        .map((img) => {
            return {
                url: BACK_HOST + "/upload/image/" + img
            }
        });
}



export const deleteImageById = async (accessToken, imageId) => {
    console.log(imageId)
    const config = {
        url: `${apiServerUrl}/api/protected/upload/image/delete/${imageId}"`,
        method: "DELETE",
        headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${accessToken}`,
        },
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

export const saveImageAndGetId = async (accessToken, file) => {
    let formData = new FormData()
    formData.append("file", file)

    const  response  = await axios.post(`${apiServerUrl}/api/protected/upload/image/new`, formData, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })

    const {data} = response

    return {
            data: data || null,
        };
};

export const delImage = async (image, jwt) => {
    const config = {
        url: `${apiServerUrl}/api/protected/upload/image/delete/${image}`,
        method: "DELETE",
        // headers: {
        //     "content-type": "application/json",
        //     Authorization: `Bearer ${jwt}`,
        // },
    };

    const { data, error } = await callExternalApi({ config });

    return {
        data: data || null,
        error,
    };
};

