import {useAuth0} from "@auth0/auth0-react";
import React from "react";
import {Button} from "antd";
import {useLocation} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";

export const LoginButton = ({redirectTo, redirectToCurrent, text}) => {
  const { loginWithRedirect } = useAuth0();

  const location = useLocation()
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: redirectToCurrent ? location.pathname : redirectTo,
      },
      authorizationParams: {

        prompt: "login",
      },

    });
  };

  return (

    <Button icon={<UserOutlined />}  type={"primary"} onClick={handleLogin}>
      {text}
    </Button>
  );
};
