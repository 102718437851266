import React, {useEffect, useRef, useState} from 'react';
import {Badge, Button, Descriptions} from "antd";
import {setRevisedById} from "../../API/services/ClientMessageService";

const ClientMessage = ({message, decrementAmountOfUnrevisedMessages, jwt}) => {

    const observer = new IntersectionObserver(callBack)
    const intersectionDivRef = useRef()
    const [intersected, setIntersected] = useState()
    const ref = useRef()

    function callBack(entries, obs) {

        if (entries[0].isIntersecting) {
            if (!intersected) {
                setIntersected(true)
                observer.unobserve(intersectionDivRef.current)
                setRevisedById(jwt, message.id)

                ref.current.click()
            }
        }
    }

    useEffect(() => {
        if (!message.isRevised) {
            observer.observe(intersectionDivRef.current)
            return ( ) => {
                observer.unobserve(intersectionDivRef.current)
            }

        }
    }, []);

    return (

        <Badge.Ribbon style={{display: message.isRevised ? "none" : "block"}} text="New message" color="green">
            <Descriptions
                style={{backgroundColor: "rgba(0,0,0,0.05)", padding: "5px 10px", marginTop: 10}}
                layout={"vertical"}
                title={message.firstName + " " +  message.lastName}>
                <Descriptions.Item label="Subject">{message.subject}</Descriptions.Item>
                <Descriptions.Item label="Time">{new Date(message.createdOn).toString()}</Descriptions.Item>
                <Descriptions.Item label="Email">{message.email}</Descriptions.Item>
                <Descriptions.Item label="Phone number">{message.email}</Descriptions.Item>
                <Descriptions.Item label="Message">{message.message}</Descriptions.Item>
            </Descriptions>
            <Button style={{display: "none"}} ref={ref} onClick={decrementAmountOfUnrevisedMessages}>dec</Button>
            <div
                ref={intersectionDivRef}
                style={{height: 20, backgroundColor: "rgba(75,75,75,0.13)"}}
            ></div>

        </Badge.Ribbon>

    );
};

export default ClientMessage;