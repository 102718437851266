import React from 'react';
import globus from "../../../assets/img/globus.jpg";
import './LanguageSelect.css'
import {Dropdown} from "antd";
import {useTranslation} from "react-i18next";
import '../../../i18n'
const LanguageSelect = () => {

    const items = [
        { key: '1', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('en')}>English</p>) },
        { key: '2', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('de')}>Deutsch</p>) },
        { key: '3', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('pl')}>język polski</p>) },
        { key: '4', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('uk')}>Українська</p>) },
        { key: '5', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('ja')}>日本語</p>) },
        { key: '6', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('zh')}>汉语/漢語</p>) },
        { key: '7', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('es')}>español</p>) },
        { key: '8', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('ar')}>عربي</p>) },
        { key: '9', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('fr')}>français</p>) },
        { key: '10', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('tr')}>Türkçe</p>) },
        { key: '11', label: (<p className={"lang"} onClick={() => i18n.changeLanguage('ru')}>Русский</p>), disabled: true },

    ];


    const { i18n } = useTranslation();

    return (
        <div className="headIconsWrapper">
            <Dropdown menu={{ items }}  trigger={['click']}>
                <div style={{display: "flex", gap: 5, cursor: "pointer"}}>
                    <img style={{height: 30}}
                         src={globus}
                         alt="language"
                    />
                    <span className={"currentLanguage"}>{i18n.language.substring(0,2)}</span>
                </div>
            </Dropdown>
        </div>
    );
};

export default LanguageSelect;